import { useState } from "react";
import NodataFoundImage from "../../../assets/img/noDataGrid.png";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { sortData } from "utils/App.utils";

const style = "flex items-center justify-center gap-1";
function SortTable({ tableData }) {
  const { headers, records } = tableData;

  const [sortConfig, setSortConfig] = useState({
    column: null,
    direction: null,
  });
  // console.log({ sortConfig });

  const handleHeaderClick = (index, header) => {
    if (header.sort === false) return;

    setSortConfig((prevSortConfig) => ({
      column: index,
      direction:
        prevSortConfig.column === index
          ? prevSortConfig.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    }));
  };

  const sortedRecords = sortData(records, sortConfig);

  return (
    <div className="w-full overflow-x-hidden overflow-x-scroll px-4">
      <table className="w-full min-w-[500px] border-collapse">
        <thead>
          <tr className=" px-2 py-3">
            {headers.map((header, index) => (
              <th
                key={index}
                className={`${
                  header.value == "" ? "max-w-14 p-2" : " px-4 py-2 text-left"
                } ${header.sort ? "cursor-pointer" : ""}  text-gray-500`}
                onClick={() => handleHeaderClick(index, header)}
              >
                {typeof header.value === "string" ? (
                  <div className={`${style}`}>
                    {sortConfig.column === index &&
                      (sortConfig.direction === "asc" ? (
                        <FaArrowDown className="size-4 text-brand-500" />
                      ) : sortConfig.direction === "desc" ? (
                        <FaArrowUp className="size-4 text-blue-500" />
                      ) : null)}
                    {header.value.split(" ").join("\u00A0")}
                  </div>
                ) : (
                  <div className={`flex items-center gap-1`}>
                    {sortConfig.column === index &&
                      (sortConfig.direction === "asc" ? (
                        <FaArrowDown className="size-4 text-brand-500" />
                      ) : sortConfig.direction === "desc" ? (
                        <FaArrowUp className="size-4 text-blue-500" />
                      ) : null)}
                    {header.value}
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {records && records.length > 0 ? (
            sortedRecords.map((record, rowIndex) => (
              <tr key={Math.random()} className=" border-t">
                {Object.entries(record).map(([key, item], rowIndex) => (
                  <td
                    key={rowIndex}
                    className={`${
                      key == "" || key.includes("__")
                        ? " max-w-14 p-1"
                        : "px-4 py-2 text-left"
                    }  `}
                  >
                    {typeof item.value === "string" ||
                    typeof item.value === "number" ? (
                      <div className={`${style}`}>
                        {typeof item.value === "string"
                          ? item.value?.split(" ").join("\u00A0")
                          : item.value}
                      </div>
                    ) : (
                      <>{item.value}</>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length}>
                <div className="flex w-full flex-col items-center justify-center gap-2 px-4 py-16">
                  <img
                    src={NodataFoundImage}
                    className="max-h-36 max-w-36 object-contain"
                    alt="No data found"
                  />
                  <h2 className="text-neutral-500 font-sans text-3xl font-bold">
                    No data
                  </h2>
                  <h4 className="font-sans text-xl font-semibold text-gray-500">
                    No data, please try again later
                  </h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SortTable;
