import { updateEnableFlag, updateIntegrationFlag } from "actions/devices/devices.action";
import Card from "components/card";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "../components/cardHeader";
import GridActionButtonDelete from "../components/gridActionButtonDelete";
import GridActionButtonEdit from "../components/gridActionButtonEdit";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import SortTable from "../components/sortTable";

const getStatusClasses = (status) => {
  switch (status) {
    case "Not Integrated":
      return "bg-red-200 text-red-800";
    case "Integrated":
      return "bg-green-200 text-green-800";
    case "Disabled":
      return "bg-red-200 text-red-800";
    case "Enabled":
      return "bg-green-200 text-green-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

function DeviceTableCard({
  devices,
  onClickAddNew,
  onClickEdit,
  onClickDelete,
}) {
  const dispatch = useDispatch();
  const { active_users_event } = useSelector((state) => state.events);

  const isActiveDevice = (deviceId) => {
    const active_devices = active_users_event.data?.vendor_app_users || [];
    const device = active_devices.find(
      (device) => device.device_id === deviceId
    );
    return !!device;
  };

  const handleEnableToggle = (device_id) => {
    dispatch(updateEnableFlag(device_id));
  };

  const handleIntegrationFlagToggle = (device_id) => {
    dispatch(updateIntegrationFlag(device_id));
  };

  const tableHeaders = [
    { value: "Device ID", sort: true },
    { value: "Name", sort: true },
    { value: "Brand", sort: true },
    { value: "Merchant", sort: true },
    { value: "Integration", sort: true },
    { value: "Enable", sort: true },
    { value: "Status", sort: true },
    { value: "Action", sort: false },
  ];
  const records = devices.map((device) => ({
    deviceId: { value: device?.device_id, sortKey: device?.device_id },
    name: { value: device?.name, sortKey: device?.name },
    brand: { value: device?.brand_id?.name, sortKey: device?.brand_id?.name },
    merchant: {
      value: device?.merchant_id?.name,
      sortKey: device?.merchant_id?.name,
    },
    is_not_integrated: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              device?.is_not_integrated ? "Not Integrated" : "Integrated"
            )}`}
          >
            {device?.is_not_integrated ? "Not\u00A0Integrated" : "Integrated"}
          </span>
        </div>
      ),
      sortKey: device?.is_not_integrated,
    },
    enable: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              device?.enable ? "Enabled" : "Disabled"
            )}`}
          >
            {device?.enable ? "Enabled" : "Disabled"}
          </span>
        </div>
      ),
      sortKey: device?.enable,
    },
    status: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`flex w-fit items-center justify-start gap-1 rounded-full px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              "default"
            )}`}
          >
            <div
              className={`size-2 rounded-full ${
                isActiveDevice(device?.id) ? "bg-green-500" : "bg-red-500"
              }`}
            />
            {isActiveDevice(device?.id) ? "Online" : "Offline"}
          </span>
        </div>
      ),
      sortKey: isActiveDevice(device?.id) ? "Online" : "Offline",
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) ? (
            <GridActionButtonEdit onClick={() => onClickEdit(device?.id)} />
          ) : null}
          {hasPermission(
            [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
            PERMISSION_ACTION_TYPES.FULL_ACCESS
          ) ? (
            <GridActionButtonDelete onClick={() => onClickDelete(device)} />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Devices"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.DEVICE_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: onClickAddNew,
                    variant: "primary",
                  },
                ]
              : null
          }
        />

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default DeviceTableCard;
