import { getUser, updateUser } from "actions/users/users.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserForm from "./userForm";
import ConfirmationModal from "../components/ConfirmationModal";
import { deleteUser } from "actions/users/users.actions";
import Loader from "components/loaderSpinner/Loader";

const UpdateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { user } = useSelector((state) => state.user);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser(userId, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(getUser(userId));
  }, [userId]);

  const handleSubmit = (payload, redirect) => {
    dispatch(updateUser(userId, payload, redirect === false ? null : navigate));
  };

  if (!user || user.id !== userId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{user.name}" user</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <UserForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={user}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateUser;
