import Card from "components/card";
import { PERMISSION_ACTION_TYPES, PERSMISSIONS_TYPES } from "constants/role.constants";
import { truncateText } from "utils/App.utils";
import { hasPermission } from "utils/permissions";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { formatLocalTimeDate } from "utils/App.utils";

function PushNotificationsTableCard({
  notifications,
  onClickAddNew,
  onClickBody,
  // onClickDelete,
}) {
  const tableHeaders = [
    "Title",
    "Body",
    "Platform",
    "Language",
    "Send Time",
    "Sent By",
    // "Action",
  ];
  const records = notifications.map((notification) => ({
    title: truncateText(notification.title, 20),
    body: (
      <div
        className="cursor-pointer "
        onClick={() => onClickBody(notification?.id)}
      >
        {truncateText(notification.body, 20)}
      </div>
    ),
    platform: notification.platform,
    language: notification?.language_id
      ? notification?.language_id?.name || ""
      : "All Languages",
    send_time: formatLocalTimeDate(notification.created_at),
    sent_by: notification?.created_by?.name ?? "",
    // actions: (
    //   <div className="flex items-center  gap-2">
    //     {hasPermission(
    //       [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
    //       PERMISSION_ACTION_TYPES.EDITOR_ACCESS
    //     ) ? (
    //       <MdOutlineEdit
    //         onClick={() => onClickEdit(notification.id)}
    //         className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
    //       />
    //     ) : null}
    //     {hasPermission(
    //       [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
    //       PERMISSION_ACTION_TYPES.FULL_ACCESS
    //     ) ? (
    //       <MdOutlineDelete
    //         onClick={() => onClickDelete(notification)}
    //         className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
    //       />
    //     ) : null}
    //   </div>
    // ),
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Push Notifications"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.PUSH_NOTIFICATION],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Send New",
                    onClick: onClickAddNew,
                    variant: "secondary",
                  },
                ]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default PushNotificationsTableCard;
