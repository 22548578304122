import { Link } from "react-router-dom";
import Card from "components/card";
import Table from "views/admin/components/table";
import { FaArrowRight } from "react-icons/fa";
import { formatLocalTimeDate } from "utils/App.utils";

function ProfileOrderTable({ orders }) {
  const headers = [
    "Order no.",
    "Platform Type",
    "Created at",
    // "Updated at",
    "Amount",
    // "Actions",
  ];
  const records = orders.map((order) => ({
    order_number: order.order_number,
    platform_type: order.platform_type,
    created_at: formatLocalTimeDate(order.created_at),
    // updated_at: dayjs(order.updated_at).format("DD,MM,YYYY"),
    amount: order.amount,
    // actions: (
    //   <div className="flex items-center justify-between gap-2">
    //     <Link
    //       to={`/transactions?order_number=${order.order_number}`}
    //       class={`
    //     cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
    //      text-sm font-medium text-gray-500 hover:text-gray-700
    //     `}
    //     >
    //       <span>View&nbsp;Transactions</span>
    //       <FaArrowRight className="h-3.5 w-3.5" />
    //     </Link>
    //   </div>
    // ),
  }));
  return (
    <Table
      tableData={{
        headers,
        records,
      }}
    />
  );
}

export default ProfileOrderTable;
