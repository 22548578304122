import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import ReportsService from "../../services/reports/ReportsService";
import * as ActionTypes from "../actionTypes";
import { downloadFile } from "utils/App.utils";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REPORT_LOADING,
    payload: loading,
  });
};

export const setReports = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REPORTS,
    payload: data,
  });
};

export const resetReportState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_REPORT_STATE,
  });
};

export const GetAllOperationReports = (month, year) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ReportsService.GetAllOperationReports(month, year);
    if (response.status == 1 && response.data) {
      dispatch(setReports(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load Operations",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const exportOperations = (month, year) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ReportsService.exportOperations(month, year);
    if (response.status == 0) {
      dispatch(
        setAlert(
          response.message ?? "Something went wrong while downloading the CSV",
          AlertTypes.ERROR
        )
      );
      return;
    }
    downloadFile(response?.data, "Operations.csv");
  } catch (error) {
    dispatch(
      setAlert(
        "Something went wrong while downloading the CSV",
        AlertTypes.ERROR
      )
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const GetAllRestaurantReports =
  (fromDate, toDate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await ReportsService.GetAllRestaurantReports(
        fromDate,
        toDate
      );
      if (response.status == 1 && response.data) {
        dispatch(setReports(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load Restaurants",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const exportRestaurants = (day) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ReportsService.exportRestaurants(day);
    if (response.status == 0) {
      dispatch(
        setAlert(
          response.message ?? "Something went wrong while downloading the CSV",
          AlertTypes.ERROR
        )
      );
      return;
    }
    downloadFile(response, "Restaurants.csv");
  } catch (error) {
    dispatch(
      setAlert(
        "Something went wrong while downloading the CSV",
        AlertTypes.ERROR
      )
    );
  } finally {
    dispatch(setLoading(false));
  }
};
