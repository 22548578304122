import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";
import { MONTHS } from "constants/app.constants";
import dayjs from "dayjs";

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();

const GetAllRestaurantReports = (
  fromDate = dayjs("2020-01-01").format("YYYY-MM-DD"),
  toDate = dayjs(new Date()).format("YYYY-MM-DD")
) => {
  const fromDateString = fromDate ? `?startDate=${fromDate}` : "";
  const toDateString = toDate ? `&endDate=${toDate}` : "";
  return RestClient.Get(
    `${API_URL.GET_RESTAURANT_REPORTS}${fromDateString}${toDateString}`
  );
};

const exportRestaurants = (day = dayjs(new Date()).format("YYYY-MM-DD")) => {
  const dayString = day ? `?date=${day}` : "";
  return RestClient.Get(`${API_URL.EXPORT_RESTAURANT_REPORTS}${dayString}`);
};

const GetAllOperationReports = (
  month = MONTHS[currentMonth],
  year = currentYear.toString()
) => {
  const monthString = month ? `?month=${month}` : "";
  const yearString = year ? `&year=${year}` : "";
  return RestClient.Get(
    `${API_URL.GET_OPERATIONS_REPORTS}${monthString}${yearString}`
  );
};

const exportOperations = (
  month = MONTHS[currentMonth],
  year = currentYear.toString()
) => {
  const monthString = month ? `?month=${month}` : "";
  const yearString = year ? `&year=${year}` : "";
  return RestClient.Get(
    `${API_URL.GET_OPERATIONS_REPORTS}${monthString}${yearString}`
  );
};

const exportedObject = {
  GetAllRestaurantReports,
  exportRestaurants,
  GetAllOperationReports,
  exportOperations,
};

export default exportedObject;
