
import { LANGUAGE_STATUS } from "constants/language.constants";
import { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages } from "actions/languages/languages.actions";
import HeadlessSelect from "../components/headless/select.headless";

const initialLanguageFilters = {
  lang_id: "",
  name: "",
  code: "",
  isRTL: "",
  locale: "",
  status: "",
}

const LanguagesFilters = () => {
  const dispatch = useDispatch();
  const { languages, loading } = useSelector((state) => state.language);
  const [filters, setFilters] = useState(initialLanguageFilters);

  const getFilteredLanguages = () => {
    const { lang_id, name, code, isRTL, locale, status } = filters;
    dispatch(getLanguages(1, 1000, lang_id, name, code, isRTL, locale, status));
  };

  const removeAllFilters = () => {
    setFilters(initialLanguageFilters);
    dispatch(getLanguages(1, 1000));
  };

  const handleOnChange = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to handle keydown event on "enter" press for any filter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getFilteredLanguages();
    }
  };

  return (
    <div className="h-full w-full">
      <div className="flex items-center justify-end gap-2 p-2">
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={removeAllFilters}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
            type="button"
          >
            <MdFilterAltOff className="h-4 w-4" />
            {"Clear Filters"}
          </button>
        </div>
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={getFilteredLanguages}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
            type="button"
          >
            <CiFilter className="h-4 w-4" />
            {"Apply Filters"}
          </button>
        </div>
      </div>

      <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
        {/* Id, Name and Code */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full md:w-1/3">
            <input
              value={filters.name}
              onChange={(e) => handleOnChange("name", e.target.value)}
              onKeyDown={handleKeyDown}
              type="text"
              name="name"
              placeholder="Name i.e English"
              className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/3">
            <input
              value={filters.code}
              onChange={(e) => handleOnChange("code", e.target.value)}
              onKeyDown={handleKeyDown}
              type="text"
              name="code"
              placeholder="Code i.e en"
              className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/3">
            <input
              value={filters.locale}
              onChange={(e) => handleOnChange("locale", e.target.value)}
              onKeyDown={handleKeyDown}
              type="text"
              name="locale"
              placeholder="Locale i.e en-US"
              className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
            />
          </div>
        </div>
        {/* Locale, isRtl (writing style) and Status */}
        <div className="flex flex-col justify-start gap-2 md:flex-row">
          <div className="w-full md:w-1/3">
            <HeadlessSelect
              title={"All writing styles"}
              options={["All", "RTL", "LTR"]}
              selected={
                filters.isRTL === "" ? "" : filters.isRTL ? "RTL" : "LTR"
              }
              onSelect={(value) =>
                handleOnChange("isRTL", value === "All" ? "" : value === "RTL")
              }
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="w-full md:w-1/3">
            <HeadlessSelect
              title={"All status"}
              options={[
                "All",
                LANGUAGE_STATUS.ACTIVE,
                LANGUAGE_STATUS.INACTIVE,
              ]}
              selected={filters.status === "" ? "" : filters.status}
              onSelect={(value) =>
                handleOnChange("status", value === "All" ? "" : value)
              }
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="w-full md:w-1/3"></div>
        </div>
      </div>
    </div>
  );
};

export default LanguagesFilters;