import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getNotifications = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_WHATSAPP_NOTIFICATIONS}?page=${page}&limit=${limit}`
  );
};
const getQueues = (page = 1, limit = 50,queue_id) => {
  return RestClient.Get(
    `${API_URL.GET_NOTIFICATION_QUEUES}?page=${page}&limit=${limit}&queue_id=${queue_id}`
  );
};
const sendNotification = (payload) => {
  return RestClient.Post(API_URL.SEND_WHATSAPP_NOTIFICATIONS, payload);
};

const exportedObject = {
  getNotifications,
  getQueues,
  sendNotification,
};

export default exportedObject;
