import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import CartRuleService from "../../services/cartRules/CartRuleService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CART_RULE_LOADING,
    payload: loading,
  });
};

export const setRules = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CART_RULES,
    payload: data,
  });
};

export const setRule = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CART_RULE,
    payload: data,
  });
};

export const updateRuleStatus = (date) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CART_RULE_PUBLISH_STATUS,
    payload: date,
  });
};

export const removeRule = (rule_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_CART_RULE,
    payload: rule_id,
  });
};

export const resetRuleState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CART_RULE_STATE,
  });
};

export const getRules = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await CartRuleService.getRules(page, limit);
    if (response.data && response.status == 1) {
      dispatch(setRules(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load Currencies",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRule = (rule_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CartRuleService.getRule(rule_id);
    if (response.status == 1) {
      dispatch(setRule(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Currrency", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createRule = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CartRuleService.createRule(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "CartRule created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/cartRules");
    }
    dispatch(
      setAlert(response.message ?? "failed to create FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateRule = (rule_id, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CartRuleService.updateRule(rule_id, payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "CartRule updated successfully",
          AlertTypes.SUCCESS
        )
      );
      // dispatch(editSlider(response.data.updatedCartRule));
      if (!navigate) return;
      return navigate("/cartRules");
    }
    dispatch(
      setAlert(response.message ?? "failed to update rule", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const toggleRulePublishStatus = (rule_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CartRuleService.toggleRulePublishStatus(rule_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "CartRule status updated successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(updateRuleStatus(response.data.updatedCartRule));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update rule status",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateLanguageRule =
  (rule_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await CartRuleService.updateLanguageRule(
        rule_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Rule localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/cartRules");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update Rule localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteRule = (rule_id, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CartRuleService.deleteRule(rule_id);
    if (response.status == 1) {
      dispatch(removeRule(rule_id));
      dispatch(
        setAlert(
          response.message ?? "CartRule deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/cartRules");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete CartRule",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};


