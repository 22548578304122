import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import SlotMachineService from "../../services/slotMachine/SlotMachineService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SLOT_MACHINE_LOADING,
    payload: loading,
  });
};

export const setSlotMachineData = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SLOT_MACHINE_DATA,
    payload: data,
  });
};

export const resetSlotMachineState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_SLOT_MACHINE_STATE,
  });
};

export const getSlotMachineData = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SlotMachineService.getSlotMachineData();
    if (response.status == 1) {
      dispatch(setSlotMachineData(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to fetch data", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveSlotMachineData = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SlotMachineService.saveSlotMachineData(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "data saved successfully",
          AlertTypes.SUCCESS
        )
      );
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to save data", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
