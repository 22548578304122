import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  rulesCount: 0,
  rules: [],
  rule: null,
  ruleMultiLang: {},
  loading: false,
};

export const cartRuleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CART_RULES:
      return {
        ...state,
        rulesCount: payload?.meta?.total_documents || 0,
        rules: payload.cartRules,
        loading: false,
      };
    case ActionTypes.SET_CART_RULE:
      return {
        ...state,
        rule: payload.cartRule,
        ruleMultiLang: payload?.ruleMultiLang || {},
        loading: false,
      };
    case ActionTypes.UPDATE_CART_RULE_PUBLISH_STATUS:
      return {
        ...state,
        rules: state.rules.map((rule) =>
          rule.id === payload.id
            ? {
                ...rule,
                is_published: payload.is_published,
              }
            : rule
        ),
        loading: false,
      };
    case ActionTypes.DELETE_CART_RULE:
      return {
        ...state,
        rulesCount: state.rulesCount - 1,
        rules: state.rules.filter((rule) => rule.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_CART_RULE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_CART_RULE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
