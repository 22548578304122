import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantTags } from "actions/merchants/merchants.actions";
import MerchantTagsTableCard from "./merchantTags-table-card";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const MerchantTags = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { merchantTagsCount, tags, loading } = useSelector(
    (state) => state.merchant
  );

  useEffect(() => {
    dispatch(getMerchantTags(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <MerchantTagsTableCard tags={tags} />

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={merchantTagsCount}
      />
    </div>
  );
};

export default MerchantTags;
