import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  data: [],
  loading: false,
};

export const slotMachineReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_SLOT_MACHINE_DATA:
      return {
        ...state,
        data: payload,
      };
    case ActionTypes.SET_SLOT_MACHINE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_SLOT_MACHINE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
