import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyTableCard from "./currency-table-card";
import { getCurrencies } from "../../../actions/currency/currency.action";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const Currencies = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { currencyCount, currencies, loading } = useSelector(
    (state) => state.currencies
  );

  useEffect(() => {
    dispatch(getCurrencies(page, limit));
  }, [page]);

  if (loading) return;
  return (
    <div className="h-full w-full">
      <CurrencyTableCard currencies={currencies} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={currencyCount}
      />
    </div>
  );
};

export default Currencies;
