
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from "actions/customers/customers.actions";
import Pagination from "../components/pagination";
import CustomersTableCard from "./customers-table-card";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import { PiExport } from "react-icons/pi";
import { exportCustomers } from "actions/customers/customers.actions";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

const Customers = () => {
  const dispatch = useDispatch();
  const { customersCount, customers, loading } = useSelector(
    (state) => state.customer
  );
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const getFilteredCustomers = () => {
    setFiltersApplied(true);
    dispatch(getCustomers(page, limit, name, email, phoneNumber));
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied;
    getFilteredCustomers();
    setFiltersApplied(prev_filter_state);
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false);
    setPage(1);
    setName("");
    setEmail("");
    // setPhoneCode('')
    setPhoneNumber("");
    dispatch(getCustomers(1, limit));
  };

  const exportTransaction = (appFilters) => {
    if (!appFilters) dispatch(exportCustomers());
    else {
      dispatch(exportCustomers(name, email, phoneNumber));
    }
  };
  // Function to handle keydown event on "enter" press for any filter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getFilteredCustomers();
    }
  };

  return (
    <div className="h-full w-full">
      <CustomersTableCard customers={customers} loading={loading}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2">
            {hasPermission(
              [PERSMISSIONS_TYPES.CUSTOMER_MANAGEMENT],
              PERMISSION_ACTION_TYPES.READ_ONLY_ACCESS
            ) ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(false)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export All"}
                </button>
              </div>
            ) : null}
            {filtersApplied ? (
              <div className="w-auto">
                <button
                  disabled={loading}
                  onClick={() => exportTransaction(true)}
                  className={`flex items-center gap-1 rounded-lg border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50`}
                  type="button"
                >
                  <PiExport className="h-4 w-4" />
                  {"Export Filtered Data"}
                </button>
              </div>
            ) : null}
          </div>

          {/* filter buttons */}
          <div className="flex items-center justify-end gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={removeAllFilters}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
                type="button"
              >
                <MdFilterAltOff className="h-4 w-4" />
                {"Clear Filters"}
              </button>
            </div>
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={getFilteredCustomers}
                className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
                type="button"
              >
                <CiFilter className="h-4 w-4" />
                {"Apply Filters"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Name, Email and Phone */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full md:w-1/3">
              <input
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyDown}
                value={name}
                type="text"
                name="name"
                placeholder="Customer Name"
                className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <input
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                value={email}
                type="text"
                name="email"
                placeholder="Customer Email"
                className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="flex w-full gap-1 md:w-1/3">
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyDown={handleKeyDown}
                value={phoneNumber}
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
                className="h-full w-auto flex-auto rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
              />
            </div>
          </div>
        </div>
      </CustomersTableCard>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={customersCount}
      />
    </div>
  );
};

export default Customers;
