import { setAlert } from "actions/alert/alert.actions";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import HeadlessSelect from "../components/headless/select.headless";
import FormFileUploader from "../components/fileUploader";
import convertToFormData from "utils/ConvertToFormData";
import { IMAGE_SIZES } from "constants/app.constants";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import TabSelector from "../components/TabSelector";
import CardHeader from "../components/cardHeader";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialMultiLangData = {
  name: "",
};

const initialClassificationData = {
  image: "",
  name: "",
  points: null,
};

const ClassificationForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { languages } = useSelector((state) => state.language);
  const { loading, classificationMultiLang } = useSelector(
    (state) => state.customerClassification
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [classificationData, setClassificationData] = useState(
    initialClassificationData
  );
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setClassificationData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = classificationMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setClassificationData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    let payload = {};

    if (lang_id) {
      payload = { ...multiLangData, lang_id };
    } else {
      payload = { ...classificationData };

      const errors = [];
      // validate data
      Object.entries(classificationData).forEach(([key, value]) => {
        if (value === "") errors.push(`${key} is required`);
      });

      if (errors.length) {
        return errors.forEach((error) =>
          dispatch(setAlert(error, AlertTypes.ERROR))
        );
      }

      // delete the properties from payload that are not allowed to send
      Object.keys(payload).forEach((key) => {
        if (initialClassificationData[key] === undefined) {
          delete payload[key];
        }
      });
    }

    const formData = convertToFormData(payload);
    handleFormSubmit(formData);
  };

  return (
    <div className="h-full w-full bg-white p-4">
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.CUSTOMER_CLASSIFICATION}
          entityId={classificationData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/customer-classifications")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Customer
              Classification page.
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Classification`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/customer-classifications"),
            variant: "dark",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}
        {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
          <Fragment>
            {/* image */}
            <div className="flex h-full w-full flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Image
              </label>
              <FormFileUploader
                size={IMAGE_SIZES.CLASSIFICATION}
                image={classificationData.image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "image", value: acceptedFiles[0] })
                }
              />
            </div>
            {/* name &  points */}
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={classificationData.name}
                  onChange={(e) => onChangeFormData(e.target)}
                  className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Points
                </label>
                <input
                  type="number"
                  name="points"
                  placeholder="Assign Points"
                  value={classificationData.points}
                  onChange={(e) => onChangeFormData(e.target)}
                  className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={multiLangData.name}
                  onChange={(e) => onChangeFormData(e.target)}
                  className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
            </div>
          </Fragment>
        )}

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${
                  loading
                    ? "bg-indigo-400 hover:bg-indigo-400"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }
              `}
          >
            {loading ? "Saving..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassificationForm;
