import Card from "components/card";
import Loader from "components/loaderSpinner/Loader";
import {
  PERMISSION_ACTION_TYPES,
  PERSMISSIONS_TYPES,
} from "constants/role.constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatLocalTimeDate } from "utils/App.utils";
import { hasPermission } from "utils/permissions";
import CardHeader from "../components/cardHeader";
import SortTable from "../components/sortTable";
import { FaArrowRight } from "react-icons/fa";

const getStatusClasses = (status) => {
  switch (status) {
    case "Not Integrated":
      return "bg-red-200 text-red-800";
    case "completed":
      return "bg-green-200 text-green-800";
    case "Disabled":
      return "bg-red-200 text-red-800";
    case "In-progress":
      return "bg-yellow-200 text-yellow-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const NotifictionTableCard = ({ notifications, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { likedBy, seenBy, loading } = useSelector((state) => state.merchant);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showSeenByModal, setShowSeenByModal] = useState(false);
  const [showLikedByModal, setShowLikedByModal] = useState(false);
  const [vibeId, setVibeId] = useState(null);

  const tableHeaders = [
    { value: "Status", sort: true },
    { value: "Type", sort: true },
    { value: "Total Records", sort: true },
    { value: "Valid Records", sort: true },
    { value: "InValid Records", sort: true },
    { value: "Tempelate Id", sort: true },
    { value: "SMS Count Per Day", sort: true },
    { value: "Total Records Sent", sort: true },
    { value: "Daily Records Sent", sort: true },
    { value: "Created At", sort: true },
    { value: "Action", sort: false },
  ];

  const records = notifications?.map((queue) => ({
    status: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              queue?.status
            )}`}
          >
            {queue?.status?.trim()?.split(/[\s-]/)?.join("\u00A0") ?? ""}
          </span>
        </div>
      ),
      sortKey: queue.status,
    },
    type: {
      value: queue.type,
      sortKey: queue.type,
    },
    total_records: {
      value: queue.total_records,
      sortKey: queue.total_records,
    },
    valid_records: {
      value: queue.valid_records,
      sortKey: queue.valid_records,
    },
    invalid_records: {
      value: queue.invalid_records,
      sortKey: queue.invalid_records,
    },
    template_id: {
      value: queue.template_id,
      sortKey: queue.template_id,
    },
    per_day_sms_count: {
      value: queue.per_day_sms_count,
      sortKey: queue.per_day_sms_count,
    },
    total_records_sent: {
      value: queue.total_records_sent,
      sortKey: queue.total_records_sent,
    },
    daily_records_sent: {
      value: queue.daily_records_sent,
      sortKey: queue.daily_records_sent,
    },
    created_at: {
      value: queue.created_at ? formatLocalTimeDate(queue.created_at) : "",
      sortKey: queue.created_at,
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.FULL_ACCESS
          ) ? (
            <Link
              to={`/whatsAppNotifications/queue/:template_id=${queue?._id}`}
              class={`
        cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
         text-sm font-medium text-gray-500 hover:text-gray-700
        `}
            >
              <span>View&nbsp;Records</span>
              <FaArrowRight className="h-3.5 w-3.5" />
            </Link>
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));
  if (loading)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <Card extra={" bg-white"}>
      <CardHeader
        label={"Whatsapp Message Queues"}
        buttons={[
          {
            text: "Send New",
            onClick: () => navigate("/whatsAppNotifications/create"),
            variant: "secondary",
          },
        ]}
      />
      {children}

      <SortTable
        tableData={{
          headers: tableHeaders,
          records,
        }}
      />
    </Card>
  );
};

export default NotifictionTableCard;
