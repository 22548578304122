import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ConfirmationModal from "../components/ConfirmationModal";
import FormFileUploader from "../components/fileUploader";
import HeadlessSelect from "../components/headless/select.headless";
import convertToFormData from "utils/ConvertToFormData";
import CardHeader from "../components/cardHeader";
import TabSelector from "../components/TabSelector";
import { FORM_TYPE } from "constants/app.constants";
import { getLanguages } from "actions/languages/languages.actions";
import { IMAGE_SIZES } from "constants/app.constants";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialMultiLangData = {
  name: "",
  image: "",
};

const initialCategoryData = {
  ...initialMultiLangData,
  category_status: "",
  display_order: 1,
};

const CategoryForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // redux-state
  const { loading, categoriesCount, categoryMultiLang } = useSelector(
    (state) => state.category
  );
  const { languages } = useSelector((state) => state.language);

  // local-state
  const [categoryData, setCategoryData] = useState({
    ...initialCategoryData,
    display_order: categoriesCount + 1,
  });
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { created_by, ...remainingData } = initialFormData;
    setCategoryData((prevData) => ({
      ...prevData,
      ...remainingData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = categoryMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  // submit-handler
  const handleSubmit = (redirect) => {
    if (
      !categoryData.name ||
      !categoryData.category_status ||
      !categoryData.image
    )
      return;

    let payload = { ...categoryData };
    if (payload.id) delete payload.id;

    if (lang_id) payload = { ...multiLangData, lang_id };
    const fromData = convertToFormData(payload);
    handleFormSubmit(fromData, redirect);
  };

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData((prevState) => ({ ...prevState, [name]: value }));
      return;
    }
    setCategoryData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="h-full w-full bg-white">
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.CATEGORY}
          entityId={categoryData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/categories")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to
              categories page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Category`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/categories"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="false flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        <div className={`flex flex-col justify-between gap-2`}>
          {/* image */}
          <div className="flex h-full w-full flex-col items-start gap-1">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Image
            </label>
            <FormFileUploader
              size={IMAGE_SIZES.CATEGORY}
              image={lang_id ? multiLangData.image : categoryData.image}
              handleUpload={(acceptedFiles) =>
                onChangeFormData({ name: "image", value: acceptedFiles[0] })
              }
            />
          </div>
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            {/* title */}
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Name
              </label>
              <input
                onChange={(e) => onChangeFormData(e.target)}
                type="text"
                name="name"
                value={lang_id ? multiLangData.name : categoryData.name}
                className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
              />
            </div>
            {/* display order  */}
            {!lang_id ? (
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Display Order
                </label>
                <input
                  onChange={(e) => onChangeFormData(e.target)}
                  type="number"
                  name="display_order"
                  value={categoryData.display_order}
                  className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
            ) : null}
          </div>
        </div>

        {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
          <Fragment>
            {/* status */}
            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Status
              </label>
              <HeadlessSelect
                title={"select Status"}
                options={["active", "inactive"]}
                selected={categoryData.category_status}
                onSelect={(value) =>
                  onChangeFormData({ name: "category_status", value })
                }
              />
            </div>
          </Fragment>
        ) : null}

        {/* buttons */}
        <div className="mt-3 flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
