import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchantTags,
  getMerchantTypes,
  getMerchants,
} from "actions/merchants/merchants.actions";
import {
  getCities,
  getCountries,
  getStates,
  setCities,
  setStates,
} from "actions/regions/regions.actions";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import Pagination from "../components/pagination";
import MerchantsTableCard from "./merchants-table-card";
import { resetRegionState } from "actions/regions/regions.actions";
import { resetMerchantState } from "actions/merchants/merchants.actions";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const Merchants = () => {
  const dispatch = useDispatch();
  const {
    merchantsCount,
    merchants,
    types: merchantTypes,
    tags: merchantTags,
    loading,
  } = useSelector((state) => state.merchant);
  const {
    countries,
    states,
    cities,
    loading: regionLoading,
  } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);
  const [tags, setTags] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const optionAll = { name: "All", value: "" };

  useEffect(() => {
    dispatch(resetMerchantState());
    dispatch(getMerchantTypes(1, 1000));
    dispatch(getMerchantTags(1, 1000));
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    setState("");
    setCity("");
    dispatch(setStates({ states: [], meta: { totalDocuments: 0 } }));
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    if (!country) return;
    const temp_country = countries.find((c) => c.id === country);
    dispatch(getStates(temp_country.code));
  }, [country]);

  useEffect(() => {
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    setCity("");
    if (!country || !state) return;
    const temp_country = countries.find((c) => c.id === country);
    const temp_state = states.find((s) => s.id === state);
    dispatch(getCities(temp_country.code, temp_state.code));
  }, [state]);

  const getFilteredMerchants = () => {
    dispatch(
      getMerchants(page, limit, name, types, tags, country, state, city)
    );
  };

  useEffect(() => getFilteredMerchants(), [page]);

  const removeAllFilters = () => {
    setPage(1);
    setName("");
    setTypes([]);
    setTags([]);
    setCountry("");
    setState("");
    setCity("");
    dispatch(getMerchants(1, limit));
  };

  // Function to handle keydown event on "enter" press for any filter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getFilteredMerchants();
    }
  };

  return (
    <div className="h-full w-full">
      <MerchantsTableCard merchants={merchants}>
        {/* filter buttons */}
        <div className="flex items-center justify-end gap-2 p-2">
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={removeAllFilters}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
              type="button"
            >
              <MdFilterAltOff className="h-4 w-4" />
              {"Clear Filters"}
            </button>
          </div>
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={getFilteredMerchants}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
              type="button"
            >
              <CiFilter className="h-4 w-4" />
              {"Apply Filters"}
            </button>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Merchant Types, Tags and Name */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full md:w-1/3">
              <input
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyDown}
                value={name}
                type="text"
                name="name"
                placeholder="Merchant Name"
                className="h-full w-full rounded-md border border-gray-300 px-2 py-2 text-sm focus:border-indigo-500 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                multiSelect={true}
                disable={!merchantTypes.length}
                title={"Select Types"}
                options={merchantTypes.map((t) => ({
                  name: t.name,
                  value: t.id,
                }))}
                selected={types}
                onSelect={setTypes}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                multiSelect={true}
                disable={!merchantTags.length}
                title={"Select Tags"}
                options={merchantTags.map((t) => ({
                  name: t.name,
                  value: t.id,
                }))}
                selected={tags}
                onSelect={setTags}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          {/* Country, State and City */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <HeadlessSelectObject
                disable={!countries.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Countries"}
                options={[
                  optionAll,
                  ...countries.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={country}
                onSelect={setCountry}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!states.length || regionLoading}
                title={regionLoading ? "Loading..." : "All States"}
                options={[
                  optionAll,
                  ...states.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={state}
                onSelect={setState}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!cities.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Cities"}
                options={[
                  optionAll,
                  ...cities.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={city}
                onSelect={setCity}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
      </MerchantsTableCard>

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={merchantsCount}
      />
    </div>
  );
};

export default Merchants;
