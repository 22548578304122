import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClaims } from "actions/membership-claims/claims.actions";
import Pagination from "../components/pagination";
import MembershipClaimTableCard from "./membershipClaim-table-card";
import ImageModal from "../components/imageModal";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const MembershipClaim = () => {
  const dispatch = useDispatch();
  const { claimsCount, claims } = useSelector((state) => state.claims);
  const [nftURL, setNftURL] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(getClaims(page, limit));
  }, [page]);

  const onClickCard = (nft) => {
    setNftURL(nft);
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setNftURL("");
  };

  return (
    <div className="h-full w-full">
      {modalOpen ? (
        <ImageModal onBack={handleCancel}>
          <img className="rounded-lg border" src={nftURL} />
        </ImageModal>
      ) : null}

      <MembershipClaimTableCard claims={claims} onClickNFT={onClickCard} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={claimsCount}
      />
    </div>
  );
};

export default MembershipClaim;
