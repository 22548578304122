import Card from "components/card";
import CardHeader from "views/admin/components/cardHeader";
import merchantPointsIcon from "../../../../assets/img/airplay.png";
import ProfileIcon from "../../../../assets/img/profile-icon.png";
import { useNavigate } from "react-router-dom";
import SortTable from "views/admin/components/sortTable";

function RestaurantsTableCard({ reports, children }) {
  const navigate = useNavigate();

  const tableHeaders = [
    {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center" />
          <div className="flex items-center gap-1">
            <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
            <p>Merchant</p>
          </div>
        </div>
      ),
      sort: false,
    },
    {
      value: "Total Transactions",
      sort: true,
    },
    {
      value: "SignUp CX",
      sort: true,
    },
    {
      value: "App Downloads %",
      sort: true,
    },
    {
      value: "App Download",
      sort: true,
    },
    {
      value: "Second Transaction %",
      sort: true,
    },
    {
      value: "Second Transaction CX",
      sort: true,
    },
  ];

  const records = reports.map((report) => ({
    merchant: {
      value: (
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => navigate(`/merchants/update/${report.merchant.id}`)}
        >
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              className="h-9 w-9 rounded-full"
              src={report?.merchant?.logo ?? ProfileIcon}
              alt=""
            />
          </div>
          {report?.merchant?.name?.trim()?.split(" ").join("\u00A0") ?? "N/A"}
        </div>
      ),
      sortKey: report?.merchant?.name,
    },
    totalTransactions: {
      value: report?.totalTransactions,
      sortKey: report?.totalTransactions,
    },
    signupCx: {
      value: report?.customersCount,
      sortKey: report?.customersCount,
    },
    appDownloadPercentage: {
      value: report?.appDownloadPercentage,
      sortKey: report?.appDownloadPercentage,
    },
    appDownloadCount: {
      value: report?.appDownloadCount,
      sortKey: report?.appDownloadCount,
    },
    secondTransactionSumAvg: {
      value: report?.secondTransactionSumAvg,
      sortKey: report?.secondTransactionSumAvg,
    },
    secondTransactionSum: {
      value: report?.secondTransactionSum,
      sortKey: report?.secondTransactionSum,
    },
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Restaurants Performance Report "} />
        {children}
        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default RestaurantsTableCard;
