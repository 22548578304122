import { getVibes } from "actions/merchants/merchants.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import InvoiceTableCard from "./invoices-table-card";
import { GetAllInvoices } from "actions/invoices/InvoiceActions";

const Invoices = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { invoicesCount, invoices, loading } = useSelector(
    (state) => state.invoice
  );

  useEffect(() => {
    dispatch(GetAllInvoices(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <InvoiceTableCard invoices={invoices} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={invoicesCount}
      />
    </div>
  );
};

export default Invoices;
