import { useDispatch, useSelector } from "react-redux";
import SpinMachineForm from "./spinMachineForm";
import { getSpinMachineData } from "actions/spinMachine/spinMachine.actions";
import { useEffect } from "react";

const SpinMachine = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state) => state.spinMachine
  );

  useEffect(() => {
    dispatch(getSpinMachineData());
  }, []);

  return (
    <div className="h-full w-full">
      <SpinMachineForm existingData={data} />
    </div>
  );
};

export default SpinMachine;
