import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import StoreCategoriesTableCard from "./store-categories-table-card";
import { getStoreCategories } from "actions/storeCategories/storeCategories.actions";

const StoreCategories = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;

  const dispatch = useDispatch();
  const { categoriesCount, categories } = useSelector(
    (state) => state.storeCategory
  );

  useEffect(() => {
    dispatch(getStoreCategories(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <StoreCategoriesTableCard categories={categories} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={categoriesCount}
      />
    </div>
  );
};

export default StoreCategories;
