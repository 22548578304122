import Card from "components/card";
import Loader from "components/loaderSpinner/Loader";
import {
  PERMISSION_ACTION_TYPES,
  PERSMISSIONS_TYPES,
} from "constants/role.constants";
import { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { formatLocalTimeDate } from "utils/App.utils";
import { hasPermission } from "utils/permissions";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import SortTable from "../components/sortTable";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import merchantPointsIcon from "assets/img/airplay.png";
import ImageModal from "../components/imageModal";
import { RECEIPT_STATUS } from "constants/app.constants";
import HeadlessSelect from "../components/headless/select.headless";
import { UpdateInvoice } from "actions/invoices/InvoiceActions";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { getCurrencies } from "actions/currency/currency.action";
import { formatNumberWithCommas } from "utils/App.utils";

const getStatusClasses = (status) => {
  switch (status) {
    case "pending":
      return "bg-yellow-200 text-yellow-800";
    case "rejected":
      return "bg-red-200 text-red-800";
    case "approved":
      return "bg-green-200 text-green-800";
    case "deleted":
      return "bg-gray-200 text-gray-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const initialReceiptData = {
  status: "",
  order_amount: 0,
  reason: "",
};

const InvoiceTableCard = ({ invoices }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.invoice);
  const [showEditModal, setShowEditModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [receiptData, setReceiptData] = useState(initialReceiptData);
  const [points, setPoints] = useState(0);
  const { currencies } = useSelector((state) => state.currencies);

  useEffect(() => {
    dispatch(getCurrencies(1, 1000));
  }, []);

  useEffect(() => {
    if (
      receiptData.status === "approved" &&
      receiptData.merchant_id &&
      receiptData.merchant_id.currency
    ) {
      const currencyPointRate =
        currencies.find((i) => i.code === receiptData.merchant_id.currency)
          ?.point_rate || 0; // Handle cases where currency is not found
      const points = receiptData.order_amount / currencyPointRate;
      setPoints(points);
    } else {
      setPoints(0); // Reset points if conditions are not met
    }
  }, [
    receiptData.status,
    receiptData.order_amount,
    receiptData.merchant_id,
    currencies,
  ]);

  const onChangeFormData = (name, value) => {
    let newValue = value;

    if (name === "order_amount") {
      const regex = /^\d{1,3}(\.\d{0,2})?$/;
      if (!regex.test(value)) {
        newValue = value.slice(0, -1); // Remove the last character if it doesn't match the regex
      }
    }

    setReceiptData((prevData) => {
      const updatedData = { ...prevData, [name]: newValue };
      if (name === "status" && (value === "rejected" || value === "approved")) {
        updatedData.reason = "";
        updatedData.order_amount = 0;
      }
      return updatedData;
    });
  };

  const handleEditClick = (invoice) => {
    if (invoice.status && invoice.status === "pending") {
      setReceiptData(invoice);
      setShowEditModal(true);
    }
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    setReceiptData(initialReceiptData);
  };

  const handleSubmit = () => {
    const { status, order_amount, reason } = receiptData;

    const errors = [];

    // Check if status is unchanged from "pending"
    if (status === "pending") {
      errors.push("Status must be changed from 'pending'.");
    }

    // Check if status is "approved" and order_amount is 0
    if (status === "approved" && order_amount === 0) {
      errors.push("Order amount cannot be 0 when status is 'approved'.");
    }

    // Check if status is "rejected" and reason is empty
    if (status === "rejected" && reason.trim() === "") {
      errors.push("Reason cannot be empty when status is 'rejected'.");
    }

    // If there are errors, display them and stop further execution
    if (errors.length > 0) {
      errors.forEach((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
      return;
    }

    let payload = { status, order_amount, reason };

    dispatch(UpdateInvoice(receiptData.id, payload));
    setShowEditModal(false);
  };

  const tableHeaders = [
    {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center" />
          <div className="flex items-center gap-1">
            <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
            <p>Merchant</p>
          </div>
        </div>
      ),
      sort: true,
    },
    {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center" />
          <div className="flex items-center gap-1">
            <p>Customer</p>
          </div>
        </div>
      ),
      sort: true,
    },
    { value: "Image", sort: false },
    { value: "Status", sort: true },
    { value: "Date", sort: true },
    // { value: "Created At", sort: true },
    { value: "Actions", sort: false },
  ];

  const records = invoices.map((invoice) => ({
    merchant: {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              className="h-9 w-9 rounded-full"
              src={invoice.merchant_id?.logo ?? ProfileIcon}
              alt=""
            />
          </div>
          {invoice.merchant_id?.name ?? ""}
        </div>
      ),
      sortKey: invoice.merchant_id?.name ?? "N/A",
    },
    customer: {
      value: (
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              className="h-9 w-9 rounded-full"
              src={invoice.customer_id?.profile_pic ?? ProfileIcon}
              alt=""
            />
          </div>
          {invoice.customer_id?.name ?? "N/A"}
        </div>
      ),
      sortKey: invoice.customer_id?.name ?? "",
    },
    image: {
      value: (
        <div className="flex cursor-pointer items-center justify-center">
          <div
            className=" h-10 w-10"
            onClick={() => {
              setImageUrl(invoice.image);
            }}
          >
            {invoice.image ? (
              <img
                className="h-10 w-10 rounded-md"
                src={invoice.image}
                alt=""
              />
            ) : null}
          </div>
        </div>
      ),
      sortKey: null,
    },
    status: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold ${getStatusClasses(
              invoice.status
            )}`}
          >
            {invoice.status.toUpperCase()}
          </span>
        </div>
      ),
      sortKey: invoice.status,
    },
    date: {
      value: invoice.date ? formatLocalTimeDate(invoice.date) : "",
      sortKey: invoice.date,
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) && invoice.status === "pending" ? (
            <MdOutlineEdit
              onClick={() => handleEditClick(invoice)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
            />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      {showEditModal ? (
        <ConfirmationModal
          onBack={handleCancelEdit}
          onConfirm={handleSubmit}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex flex-col gap-2">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex w-full items-center justify-center text-xl font-semibold ">
                  Edit Invoice status
                </div>
                <label
                  for="content"
                  className="block text-sm font-medium text-gray-700 "
                >
                  Specify amount for approval or reason for rejection
                </label>
                {/* form */}
                <>
                  <label
                    for="content"
                    className="block place-self-start text-base font-medium text-navy-800"
                  >
                    Status
                  </label>
                  <HeadlessSelect
                    disable={loading}
                    title={"select status"}
                    options={Object.values(RECEIPT_STATUS)}
                    selected={receiptData.status}
                    onSelect={(value) => onChangeFormData("status", value)}
                  />
                </>
                <div className="flex flex-col justify-between gap-1">
                  {/* {receiptData.status === "approved" && ( */}
                  <>
                    <label
                      for="content"
                      className="block place-self-start text-base font-medium text-navy-800"
                    >
                      {`Order Amount (${
                        receiptData?.merchant_id?.currency ?? "SAR"
                      })`}
                    </label>

                    <input
                      type="number"
                      name="order_amount"
                      disabled={
                        !receiptData.status || receiptData.status !== "approved"
                      }
                      placeholder="Order Amount"
                      value={receiptData.order_amount}
                      onChange={(e) =>
                        onChangeFormData("order_amount", e.target.value)
                      }
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                    />
                    <label
                      for="content"
                      className="block place-self-start text-sm font-medium text-brand-500"
                    >
                      {`Points: ${formatNumberWithCommas(
                        Number(points || 0).toFixed(2)
                      )}`}
                    </label>
                  </>
                  {/* )} */}
                  {receiptData.status === "rejected" && (
                    <>
                      <label
                        for="content"
                        className="block place-self-start text-base font-medium text-navy-800"
                      >
                        Reason
                      </label>

                      <textarea
                        required
                        name="reason"
                        value={receiptData.reason}
                        onChange={(e) =>
                          onChangeFormData("reason", e.target.value)
                        }
                        className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                        rows="2"
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </ConfirmationModal>
      ) : null}

      {imageUrl ? (
        <ImageModal onBack={() => setImageUrl(null)}>
          <img src={imageUrl} alt="Image" />
        </ImageModal>
      ) : null}

      <Card extra={" bg-white"}>
        <CardHeader label={"Manual Invoices"} />
        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default InvoiceTableCard;
