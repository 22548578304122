import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  ordersCount: 0,
  orders: [],
  order: null,
  loading: false,
};

export const affiliateOrdersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_AFFILIATE_ORDERS:
      return {
        ...state,
        ordersCount: payload?.meta?.total_documents || 0,
        orders: payload.affiliateOrders,
        loading: false,
      };
    case ActionTypes.SET_AFFILIATE_ORDER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_AFFILIATE_ORDER_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
