import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { deleteMerchantPromotionTag } from "actions/merchantPromotionTags/merchantPromotionTags.action";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { truncateText } from "utils/App.utils";
import ImageModal from "../components/imageModal";
import SortTable from "../components/sortTable";

function MerchantPromotionTagTableCard({ promotionTags }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [promotionTag, setPromotionTag] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const onClickDelete = (tag) => {
    setPromotionTag(tag);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantPromotionTag(promotionTag.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setPromotionTag(null);
  };

  const tableHeaders = [
    { value: "Merchant", sort: true },
    { value: "Image", sort: false },
    { value: "Title", sort: true },
    { value: "Description", sort: true },
    { value: "Created By", sort: true },
    { value: "Actions", sort: false },
  ];
  const records = promotionTags.map((promotionTagItem) => ({
    merchant_name: {
      value: promotionTagItem.merchant_id.name,
      sortKey: promotionTagItem.merchant_id.name,
    },
    image: {
      value: (
        <div className="flex items-center justify-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center">
            <img
              onClick={() => setImageUrl(promotionTagItem?.image || "")}
              className="h-10 w-10 cursor-pointer rounded-md"
              src={promotionTagItem?.image || ""}
              alt=""
            />
          </div>
        </div>
      ),
      sortKey: null,
    },
    title: {
      value: promotionTagItem?.title ?? "",
      sortKey: promotionTagItem?.title ?? "",
    },
    description: {
      value: truncateText(promotionTagItem.description, 20),
      sortKey: promotionTagItem.description,
    },
    created_by: {
      value: promotionTagItem?.created_by?.name ?? "",
      sortKey: promotionTagItem?.created_by?.name ?? "",
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) ? (
            <MdOutlineEdit
              onClick={() =>
                navigate(
                  `/merchant-promotion-tags/update/${promotionTagItem.id}`
                )
              }
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
            />
          ) : null}
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.FULL_ACCESS
          ) ? (
            <MdOutlineDelete
              onClick={() => onClickDelete(promotionTagItem)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
            />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      {imageUrl ? (
        <ImageModal onBack={() => setImageUrl(null)}>
          <img className="rounded-lg border" src={imageUrl} />
        </ImageModal>
      ) : null}
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the "{promotionTag.description}" promotion
              tag
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" w-full bg-white"}>
        <CardHeader
          label={"Merchant Promotion Messages"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/merchant-promotion-tags/create"),
                    variant: "primary",
                  },
                ]
              : null
          }
        />

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MerchantPromotionTagTableCard;
