import {
  deleteLanguage,
  getLanguages
} from "actions/languages/languages.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../components/ConfirmationModal";
import LanguagesTableCard from "./languages-table-card";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const Languages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { languagesCount, languages, loading } = useSelector(
    (state) => state.language
  );
  const [showDelModal, setShowDelModal] = useState(false);
  const [delLanguage, setDelLanguage] = useState(null);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(getLanguages(page, limit));
  }, [page]);

  const handleCancel = () => {
    setShowDelModal(false);
    setDelLanguage(null);
  };

  const onClickDelete = (lang) => {
    setDelLanguage(lang);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteLanguage(delLanguage.id));
    handleCancel();
  };

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{delLanguage.name}" language</h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <LanguagesTableCard
        languages={languages}
        onClickAddNew={() => navigate("/languages/create")}
        onClickEdit={(language_id) =>
          navigate(`/languages/update/${language_id}`)
        }
        onClickDelete={onClickDelete}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={languagesCount}
      />
    </div>
  );
};

export default Languages;
