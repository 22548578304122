import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

export const getAffiliateOrders = (
  page = 1,
  limit = 50,
  customer_id,
  store_id,
  order_number,
  startDate,
  endDate
) => {
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const storeId = store_id ? `&store_id=${store_id}` : "";
  const orderNumber = order_number ? `&order_number=${order_number}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  return RestClient.Get(
    `${API_URL.GET_AFFILIATE_ORDERS}?page=${page}&limit=${limit}${storeId}${customer}${start_date}${end_date}${orderNumber}`
  );
};

export const exportAffiliateOrders = (
  merchant_id,
  customer_id,
  country_id,
  state_id,
  city_id,
  startDate,
  endDate
) => {
  const merchant = merchant_id ? `&merchant_id=${merchant_id}` : "";
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const country = country_id ? `&country_id=${country_id}` : "";
  const state = state_id ? `&state_id=${state_id}` : "";
  const city = city_id ? `&city_id=${city_id}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  return RestClient.Get(
    `${API_URL.EXPORT_AFFILIATE_ORDERS}?${merchant}${customer}${country}${state}${city}${start_date}${end_date}`
  );
};

const exportedObject = {
  getAffiliateOrders,
  exportAffiliateOrders,
};

export default exportedObject;
