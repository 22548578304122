import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getSpinMachineData = () => {
  return RestClient.Get(`${API_URL.GET_SPIN_MACHINE_DATA}`);
};

const saveSpinMachineData = (payload) => {
  return RestClient.Post(API_URL.SAVE_SPIN_MACHINE_DATA, payload);
};

const exportedObject = {
  getSpinMachineData,
  saveSpinMachineData,
};

export default exportedObject;
