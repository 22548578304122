import Card from "components/card";
import Loader from "components/loaderSpinner/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatLocalTimeDate } from "utils/App.utils";
import CardHeader from "../components/cardHeader";
import SortTable from "../components/sortTable";
import Pagination from "../components/pagination";
import { getQueues } from "actions/whatsappNotifications/whatsappNotification.action";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const getStatusClasses = (status) => {
  switch (status) {
    case "success":
      return "bg-green-200 text-green-800";
    case "sent":
      return "bg-green-200 text-green-800";
    case "delivered":
      return "bg-yellow-200 text-yellow-800";
    case "in-progress":
      return "bg-yellow-200 text-yellow-800";
    case "pending":
      return "bg-yellow-200 text-yellow-800";
    case "parameters":
      return "bg-blue-200 text-blue-800";
    case "read":
      return "bg-blue-200 text-blue-800";
    case "invalid":
      return "bg-red-200 text-red-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const NotifictionQueuesTableCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { template_id } = useParams();
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const { queuesCount, queues, loading } = useSelector(
    (state) => state.whatsappNotifications
  );

  useEffect(() => {
    dispatch(getQueues(page, limit, template_id));
  }, [page, template_id]);

  const tableHeaders = [
    { value: "Status", sort: true },
    { value: "Phone Number", sort: false },
    { value: "WhatsApp Status", sort: true },
    { value: "Parameters", sort: false },
    { value: "Created At", sort: true },
  ];

  const records = queues.map((record) => ({
    status: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              record?.status
            )}`}
          >
            {record?.status
              ?.trim()
              ?.split(/[\s-]/)
              ?.join("\u00A0")
              ?.toUpperCase() ?? ""}
          </span>
        </div>
      ),
      sortKey: record?.status ?? "",
    },
    phone_number: {
      value: record.phone_number,
      sortKey: null,
    },
    whatsapp_status: {
      value: (
        <div className="flex items-center justify-center">
          <span
            className={`rounded-full  px-2 py-1 text-xs font-semibold  ${getStatusClasses(
              record?.whatsapp_status ?? "default"
            )}`}
          >
            {record?.whatsapp_status?.replace(/_/g, "\u00A0")?.toUpperCase() ??
              "N/A"}
          </span>
        </div>
      ),
      sortKey: record?.whatsapp_status ?? "",
    },
    parameters: {
      value: (
        <div className="flex items-center justify-between gap-1">
          {record?.parameters && record.parameters.length
            ? record?.parameters?.map((parameter, index) => (
                <span
                  key={index}
                  className={`flex  min-w-24 items-center justify-center rounded-full px-2 py-1 text-xs font-semibold  ${getStatusClasses(
                    "parameters"
                  )}`}
                >
                  {parameter?.value}
                </span>
              ))
            : null}
        </div>
      ),
      sortKey: null,
    },
    created_at: {
      value: record.created_at ? formatLocalTimeDate(record.created_at) : "",
      sortKey: record.created_at,
    },
  }));

  if (loading || !template_id)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Message Queue Records"}
          buttons={[
            {
              text: "Back",
              onClick: () => navigate(-1),
              variant: "dark",
            },
          ]}
        />

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={queuesCount}
      />
    </div>
  );
};

export default NotifictionQueuesTableCard;
