import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import TabSelector from "../components/TabSelector";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";
import FormFileUploader from "../components/fileUploader";
import { MdOutlineDelete } from "react-icons/md";
import FormFileUploaderIcon from "../components/fileUploaderIcon";
import { saveSpinMachineData } from "actions/spinMachine/spinMachine.actions";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";

const pointsObject = {
  points: 0,
  probability: 0,
  display_order: 0,
}

const initialSpinMachineData = [
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject
];

const SpinMachineForm = ({ existingData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [spinMachineData, setSpinMachineData] = useState(initialSpinMachineData)
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!existingData) return;
    setSpinMachineData(existingData);
  }, [existingData]);

  const handleOnChange = (index, key, value) => {
    setSpinMachineData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const handleChangeNumber = (index, key, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      handleOnChange(index, key, value);
    }
  };

  const handleSubmit = () => {
    let payload = {
      data: spinMachineData.map(item => ({
        ...item,
        points: Number(item.points),
        display_order: Number(item.display_order),
        probability: Number(item.probability),
      }))
    };

    const probabilitySum = payload.data.reduce(
      (sum, obj) => sum + (obj.probability || 0),
      0
    );

    if (probabilitySum !== 100) {
      return dispatch(setAlert("the sum of probabilities shoul be 100", AlertTypes.ERROR))
    }
    dispatch(saveSpinMachineData(payload))
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {/* {showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.SPIN_MACHINE_GAME}
          entityId={typeData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null} */}
      <CardHeader
        label={`Spin Wheel`}
        buttons={[
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* display order  */}
        <div className="flex w-full justify-between items-center gap-2">
          <label
            className="flex min-w-32 text-base font-medium text-gray-800"
          >
            Display Order
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Points
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Probability
          </label>
        </div>
        {spinMachineData.map((item, index) => (
          <div key={index} className="flex justify-between items-center gap-2">
            {/* display order  */}
            <input
              type="text"
              name="display_order"
              value={item.display_order}
              onChange={(e) => handleChangeNumber(index, "display_order", isNaN(e.target.value) ? item.probability : Number(e.target.value))}
              className="flex min-w-32 rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            />
            {/* Points */}
            <input
              type="text"
              name="points"
              placeholder="Points"
              value={item.points}
              onChange={(e) => handleChangeNumber(index, "points", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
            <input
              type="text"
              name="probability"
              placeholder="10.0"
              value={item.probability}
              onChange={(e) => handleChangeNumber(index, "probability", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        ))}
        <div className="flex w-full justify-between items-center gap-2">
          <label
            className="flex min-w-32 text-base font-medium text-gray-800"
          >
            {""}
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            {""}
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            <span>Probability Sum: {spinMachineData.reduce((sum, obj) => sum + Number(obj.probability || 0), 0)}</span>
          </label>
        </div>
        {/* {error ? <span className='text-red-600 p-2'>{error}</span> : null} */}
        {/* <div className="flex justify-start gap-3 my-3">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
              }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default SpinMachineForm;
