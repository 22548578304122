import { saveSlotMachineData } from "actions/slotMachine/slotMachine.actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import { setAlert } from "actions/alert/alert.actions";
import { AlertTypes } from "reducers/alert/alert.reducer";

const pointsObject = {
  points: 0,
  probability_wheel_1: 0,
  probability_wheel_2: 0,
  probability_wheel_3: 0,
  display_order: 0,
  symbol: "",
}

const initialSlotMachineData = [
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject,
  pointsObject
];

const SlotMachineForm = ({ existingData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [slotMachineData, setSlotMachineData] = useState(initialSlotMachineData)
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!existingData) return;
    setSlotMachineData(existingData);
  }, [existingData]);

  const handleOnChange = (index, key, value) => {
    setSlotMachineData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const handleChangeNumber = (index, key, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      handleOnChange(index, key, value);
    }
  };

  const handleSubmit = () => {
    let payload = {
      data: slotMachineData.map(item => ({
        ...item,
        points: Number(item.points),
        display_order: Number(item.display_order),
        probability_wheel_1: Number(item.probability_wheel_1),
        probability_wheel_2: Number(item.probability_wheel_2),
        probability_wheel_3: Number(item.probability_wheel_3),
      }))
    };

    const probabilitySum_1 = payload.data.reduce(
      (sum, obj) => sum + (obj.probability_wheel_1 || 0),
      0
    );
    const probability_wheel_2 = payload.data.reduce(
      (sum, obj) => sum + (obj.probability_wheel_2 || 0),
      0
    );
    const probability_wheel_3 = payload.data.reduce(
      (sum, obj) => sum + (obj.probability_wheel_3 || 0),
      0
    );

    if (
      probabilitySum_1 !== 100 ||
      probability_wheel_2 !== 100 ||
      probability_wheel_3 !== 100
    ) {
      return dispatch(setAlert("the sum of probabilities shoul be 100", AlertTypes.ERROR))
    }
    dispatch(saveSlotMachineData(payload))
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {/* {showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.SPIN_MACHINE_GAME}
          entityId={typeData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null} */}
      <CardHeader
        label={`Slot Machine`}
        buttons={[
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* display order  */}
        <div className="flex w-full justify-between items-center gap-2">
          <label
            className="flex min-w-24 text-base font-medium text-gray-800"
          >
            Display Order
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Points
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Probability 1
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Probability 2
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            Probability 3
          </label>
          <label
            className="flex min-w-24 text-base font-medium text-gray-800"
          >
            Symbol
          </label>
        </div>
        {slotMachineData.map((item, index) => (
          <div key={index} className="flex justify-between items-center gap-2">
            {/* display order  */}
            <input
              type="text"
              name="display_order"
              value={item.display_order}
              onChange={(e) => handleChangeNumber(index, "display_order", isNaN(e.target.value) ? item.probability : Number(e.target.value))}
              className="flex min-w-24 rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
            />
            {/* Points */}
            <input
              type="text"
              name="points"
              placeholder="Points"
              value={item.points}
              onChange={(e) => handleChangeNumber(index, "points", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
            <input
              type="text"
              name="probability_wheel_1"
              placeholder="10.0"
              value={item.probability_wheel_1}
              onChange={(e) => handleChangeNumber(index, "probability_wheel_1", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
            <input
              type="text"
              name="probability_wheel_2"
              placeholder="10.0"
              value={item.probability_wheel_2}
              onChange={(e) => handleChangeNumber(index, "probability_wheel_2", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
            <input
              type="text"
              name="probability_wheel_3"
              placeholder="10.0"
              value={item.probability_wheel_3}
              onChange={(e) => handleChangeNumber(index, "probability_wheel_3", e.target.value)}
              className="flex w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
            <input
              type="text"
              name="symbol"
              placeholder=""
              value={item.symbol}
              onChange={(e) => handleOnChange(index, "symbol", e.target.value)}
              className="flex min-w-24 rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
        ))}
        <div className="flex w-full justify-between items-center gap-2">
          <label
            className="flex min-w-24 text-base font-medium text-gray-800"
          >
            {""}
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            {""}
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            <span>Probability Sum: {slotMachineData.reduce((sum, obj) => sum + Number(obj.probability_wheel_1 || 0), 0)}</span>
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            <span>Probability Sum: {slotMachineData.reduce((sum, obj) => sum + Number(obj.probability_wheel_2 || 0), 0)}</span>
          </label>
          <label
            className="flex w-full text-base font-medium text-gray-800"
          >
            <span>Probability Sum: {slotMachineData.reduce((sum, obj) => sum + Number(obj.probability_wheel_3 || 0), 0)}</span>
          </label>
          <label
            className="flex min-w-24 text-base font-medium text-gray-800"
          >
            {""}
          </label>
        </div>
        {/* {error ? <span className='text-red-600 p-2'>{error}</span> : null} */}
        <div className="flex justify-start gap-3">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
              }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlotMachineForm;
