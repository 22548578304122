import { getUser, updateUser } from "actions/users/users.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteUser } from "actions/users/users.actions";
import UserSettingForm from "./userSettingsForm";
import Loader from "components/loaderSpinner/Loader";

const UserSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUser(userId));
  }, [userId]);

  const handleSubmit = (payload, redirect) => {
    dispatch(updateUser(userId, payload, redirect === false ? null : navigate));
  };

  if (!user || user.id !== userId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  return (
    <div className="h-full w-full">
      <UserSettingForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={user}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
};

export default UserSettings;
