import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import SettingsTableCard from "./settings-table-card";
import ConfirmationModal from "../components/ConfirmationModal";
import SettingsForm from "./settingsForm";
import { FORM_TYPE } from "constants/app.constants";
import { getSettings } from "actions/settings/settings.action";
import CardHeader from "../components/cardHeader";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { hasPermission } from "utils/permissions";
import Card from "components/card";
import { createSetting } from "actions/settings/settings.action";
import { updateSetting } from "actions/settings/settings.action";
import { deleteSetting } from "actions/settings/settings.action";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const Settings = () => {
  const dispatch = useDispatch();
  const { settingsCount, settings, systemSettings, loading } = useSelector(
    (state) => state.setting
  );
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const [settingId, setSettingId] = useState("");
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenDelete, setModalOpenDelete] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getSettings(page, limit));
  }, [page]);

  const onClickAddNew = () => {
    setModalOpenAdd(true);
  };

  const onClickEdit = (setting) => {
    setSettingId(setting);
    setModalOpenEdit(true);
  };

  const onClickDelete = (setting) => {
    setSettingId(setting);
    setModalOpenDelete(true);
  };

  const handleCancel = () => {
    setModalOpenAdd(false);
    setModalOpenEdit(false);
    setModalOpenDelete(false);
    setSettingId("");
  };

  const handleConfirmAddNew = (key, value) => {
    dispatch(
      createSetting({
        key,
        value,
      })
    );
  };
  const handleConfirmEdit = (key, value) => {
    dispatch(
      updateSetting(settingId, {
        key,
        value,
      })
    );
  };
  const handleConfirmDelete = () => {
    dispatch(deleteSetting(settingId));
    handleCancel();
  };

  return (
    <div className="h-full w-full">
      {modalOpenAdd ? (
        <SettingsForm
          formType={FORM_TYPE.CREATE}
          handleSubmit={({ key, value }) => handleConfirmAddNew(key, value)}
          handleCancel={handleCancel}
        />
      ) : null}
      {modalOpenEdit ? (
        <SettingsForm
          formType={FORM_TYPE.UPDATE}
          initialFormData={
            settings.find((setting) => setting.id === settingId) || {}
          }
          handleSubmit={({ key, value }) => handleConfirmEdit(key, value)}
          handleCancel={handleCancel}
        />
      ) : null}
      {modalOpenDelete ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText="Confirm"
        >
          <div className="flex flex-col gap-4">
            <h1>Delete Confirmation</h1>
            <p className="text-sm text-gray-700">
              You're about to a delete setting with key&nbsp;"
              {settings.find((setting) => setting.id === settingId)?.key ?? ""}"
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <Card extra={" w-full bg-white "}>
        <CardHeader
          label={"Settings"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.SETTINGS_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                {
                  text: "Add New",
                  onClick: onClickAddNew,
                  variant: "primary",
                },
              ]
              : null
          }
        />
        <SettingsTableCard
          settings={settings}
          onClickEdit={(id) => onClickEdit(id)}
          onClickDelete={(id) => onClickDelete(id)}
        />
      </Card>

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={settingsCount}
      />
    </div>
  );
};

export default Settings;
