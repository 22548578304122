import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { FaArrowRight } from "react-icons/fa";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import { useNavigate } from "react-router-dom";

const getStatusClasses = (status) => {
  switch (status) {
    case "open":
      return "bg-yellow-200 text-yellow-800";
    case "inprogress":
      return "bg-blue-200 text-blue-800";
    case "resolved":
      return "bg-green-200 text-green-800";
    case "closed":
      return "bg-gray-200 text-gray-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

function TicketTableCard({ tickets, onClickEdit }) {
  const navigate = useNavigate();
  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Name"}
    </div>,
    "Title",
    "Ticket Number",
    "Support Type",
    "Ticket Status ",
    "Actions",
  ];
  const records = tickets.map((ticket) => ({
    attachment: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 cursor-pointer items-center justify-center">
          <img
            className="h-full w-full rounded-full"
            src={ticket?.user_id?.profile_pic ?? ProfileIcon}
            alt=""
            onClick={() => navigate(`/customer/profile/${ticket?.user_id?.id}`)}
          />
        </div>
        {ticket.user_id?.name.trim()?.split(" ").join("\u00A0") ?? "N/A"}
      </div>
    ),
    title: ticket.title,
    ticket_number: ticket.ticket_number,
    support_type: ticket.support_type,
    support_type: ticket.support_type,
    // ticket_status: ticket.ticket_status,
    ticket_status: (
      <span
        className={`rounded-full  px-2 py-1 text-xs font-semibold ${getStatusClasses(
          ticket.ticket_status
        )}`}
      >
        {ticket.ticket_status.toUpperCase()}
      </span>
    ),
    actions: (
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() => onClickEdit(ticket.id)}
          class={`
        cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
         text-sm font-medium text-gray-500 hover:text-gray-700
        `}
        >
          <span>View&nbsp;Ticket</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"h-full min-h-[500px] w-full bg-white"}>
        <CardHeader
          label={"Tickets"}
          // button={{
          //   text: "Add New",
          //   onClick: onClickAddNew,
          //   variant: "primary",
          // }}
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default TicketTableCard;
