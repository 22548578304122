import { getSlotMachineData } from "actions/slotMachine/slotMachine.actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlotMachineForm from "./slotMachineForm";

const SlotMachine = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector(
    (state) => state.slotMachine
  );

  useEffect(() => {
    dispatch(getSlotMachineData());
  }, []);

  return (
    <div className="h-full w-full">
      <SlotMachineForm existingData={data} />
    </div>
  );
};

export default SlotMachine;
