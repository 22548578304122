import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import NotifictionTableCard from "./notifications-table-card";
import { getNotifications } from "actions/whatsappNotifications/whatsappNotification.action";

const WhatsappNotification = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { notificationsCount, notifications } =
    useSelector((state) => state.whatsappNotifications);

  useEffect(() => {
    dispatch(getNotifications(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <NotifictionTableCard notifications={notifications} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={notificationsCount}
      />
    </div>
  );
};

export default WhatsappNotification;
