import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  notificationsCount: 0,
  notifications: [],
  queuesCount: 0,
  queues: [],
  loading: false,
};

export const whatsappNotificationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notificationsCount: payload?.meta?.total_documents || 0,
        notifications: payload.messageQueues,
        loading: false,
      };
    case ActionTypes.SET_QUEUES:
      return {
        ...state,
        queuesCount: payload?.meta?.total_documents || 0,
        queues: payload.messageQueueRecords,
        loading: false,
      };
    case ActionTypes.SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_NOTIFICATIONS_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
