import { useDispatch, useSelector } from "react-redux";
import StoreLoyaltyTableCard from "./storeLoyalty-table-card";
import { getStoreLoyalties } from "actions/storeLoyalties/storeLoyalties.action";
import { useEffect, useState } from "react";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const StoreLoyalty = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { storeLoyaltiesCount, storeLoyalties } = useSelector(
    (state) => state.storeLoyalty
  );

  useEffect(() => {
    dispatch(getStoreLoyalties(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <StoreLoyaltyTableCard storeLoyalties={storeLoyalties} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={storeLoyaltiesCount}
      />
    </div>
  );
};

export default StoreLoyalty;
