import { getVibes } from "actions/merchants/merchants.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VibesTableCard from "./vibes-table-card";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";

const Vibes = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { vibesCount, vibes, loading } = useSelector((state) => state.merchant);

  useEffect(() => {
    dispatch(getVibes(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <VibesTableCard vibes={vibes} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={vibesCount}
      />
    </div>
  );
};

export default Vibes;
