import { setAlert } from "actions/alert/alert.actions";
import { getMerchants } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AlertTypes } from "reducers/alert/alert.reducer";
import {
  convertDateTimeToUTC,
  formatLocalTimeDate,
  submitFormHandler,
} from "utils/App.utils";
import convertToFormData from "utils/ConvertToFormData";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import MediaUploader from "../components/mediaUploader";
import { FILE_TYPE } from "constants/app.constants";
import { getMerchantVibeCategories } from "actions/vibeCategories/vibeCategories.action";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";
import { MdCancel } from "react-icons/md";
import { FaPlug, FaPlus, FaTrash } from "react-icons/fa";
import { SUBSCRIPTION_PERIOD } from "constants/pass.constants";
import { getLanguages } from "actions/languages/languages.actions";
import FormFileUploader from "../components/fileUploader";
import MultiSelect from "../components/multiSelect";
import FormSectionHeader from "../components/formSectionHeader";
import HeadlessSelect from "../components/headless/select.headless";

const initilMultiLangData = {
  title: "",
};

const initialPassData = {
  ...initilMultiLangData,
  description: "",
  benefits: [], // array of objects { title, sub_title }
  subscription_period: {
    period_type: SUBSCRIPTION_PERIOD.MONTHLY,
    duration: 1,
  },
  amount: 0,
  terms: "",
  pass_image: "",
  merchants: [],
};

const PassForm = ({ formType, initialFormData, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, passMultiLang } = useSelector((state) => state.pass);
  const { merchants, loading: merchantLoading } = useSelector(
    (state) => state.merchant
  );
  const { languages } = useSelector((state) => state.language);
  // local-state
  const [passData, setPassData] = useState(initialPassData);
  const [multiLangData, setMultiLangData] = useState(initilMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const formRef = useRef(null);
  const shouldRedirect = useRef(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [showLogsModal, setShowLogsModal] = useState(false);
  // console.log({ passData });
  useEffect(() => {
    if (!initialFormData) return;
    setPassData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
    dispatch(getMerchants(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = passMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initilMultiLangData, ...langData });
  }, [lang_id]);

  // submit-handler
  const handleSubmit = (e) => {
    e?.preventDefault(e);

    const { title } = passData;

    const errors = [];
    if (!title) errors.push("title is required");
    if (errors.length) {
      errors.map((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
      return;
    }

    let payload = { ...passData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialPassData[key] == undefined) {
        delete payload[key];
      }
    });
    // console.log(payload);

    const formData = convertToFormData(payload);
    // console.log({ formData });
    handleFormSubmit(formData, shouldRedirect.current);
    shouldRedirect.current = true;
  };

  const onChangeFormData = ({ name, value }) => {
    setPassData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeNumber = ({ name, value }) => {
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      onChangeFormData({
        name,
        value: !isNaN(numericValue) ? numericValue : 0,
      });
    } else if (value === "") {
      onChangeFormData({ name, value: 0 });
    }
  };

  const onChangeSubscriptionPeriod = ({ name, value }) => {
    const updatedSubscriptionPeriod = {
      ...passData.subscription_period,
      [name]:
        name === "duration"
          ? value === ""
            ? 1
            : !isNaN(parseFloat(value))
            ? parseFloat(value)
            : 1
          : value,
    };

    onChangeFormData({
      name: "subscription_period",
      value: updatedSubscriptionPeriod,
    });
  };

  const handleBenifitChange = (index, field, value) => {
    const updatedBenefits = [...passData.benefits];
    updatedBenefits[index] = {
      ...updatedBenefits[index],
      [field]: value,
    };
    setPassData({ ...passData, benefits: updatedBenefits });
  };

  const addBenefit = () => {
    const newBenefit = { title: "", sub_title: "" }; // Default values for new benefit
    setPassData({ ...passData, benefits: [...passData.benefits, newBenefit] });
  };

  const removeBenefit = (index) => {
    const updatedBenefits = passData.benefits.filter((_, i) => i !== index);
    setPassData({ ...passData, benefits: updatedBenefits });
  };

  return (
    <div className="h-full w-full bg-white">
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.PASS}
          entityId={passData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/passes")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to passes
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Pass`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/passes"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => {
              submitFormHandler(formRef.current);
            },
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => {
                    shouldRedirect.current = false;
                    submitFormHandler(formRef.current);
                  },
                  variant: "primary",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <div className={`flex flex-col justify-between gap-2`}>
            {/* image */}
            <div className="flex h-full w-full flex-col items-start gap-1">
              <label
                for="image"
                className="mb-1 block text-base font-medium text-gray-800"
              >
                Image
              </label>

              <FormFileUploader
                // size={IMAGE_SIZES.SLIDER}
                image={lang_id ? multiLangData.pass_image : passData.pass_image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({
                    name: "pass_image",
                    value: acceptedFiles[0],
                  })
                }
              />
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="flex w-full flex-col gap-2 md:flex-row">
                {/* Title */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={passData.title}
                    onChange={(e) =>
                      onChangeFormData({
                        name: "title",
                        value: e.target.value,
                      })
                    }
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                {/* Description */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={passData.description}
                    onChange={(e) =>
                      onChangeFormData({
                        name: "description",
                        value: e.target.value,
                      })
                    }
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              <FormSectionHeader text="Subscription Period" />
              <div className="flex w-full flex-col gap-2 md:flex-row">
                {/* period_type */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Period Type
                  </label>
                  <HeadlessSelect
                    title={"Select subscription period"}
                    options={Object.values(SUBSCRIPTION_PERIOD)}
                    selected={passData.subscription_period.period_type}
                    onSelect={(value) =>
                      onChangeSubscriptionPeriod({ name: "period_type", value })
                    }
                  />
                </div>
                {/* Duration */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Duration
                  </label>
                  <input
                    type="text"
                    name="duration"
                    placeholder="Duration"
                    value={passData.subscription_period.duration}
                    onChange={(e) =>
                      onChangeSubscriptionPeriod({
                        name: "duration",
                        value: e.target.value,
                      })
                    }
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              <div className="flex w-full flex-col gap-2 md:flex-row">
                {/* Amount */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Amount
                  </label>
                  <input
                    type="text"
                    name="amount"
                    placeholder="Amount"
                    value={passData.amount}
                    onChange={(e) =>
                      handleChangeNumber({
                        name: "amount",
                        value: e.target.value,
                      })
                    }
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
                {/* Terms */}
                <div className="w-full md:w-1/2">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Terms
                  </label>
                  <input
                    type="text"
                    name="terms"
                    placeholder="Terms"
                    value={passData.terms}
                    onChange={(e) =>
                      onChangeFormData({
                        name: "terms",
                        value: e.target.value,
                      })
                    }
                    className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                  />
                </div>
              </div>

              {/* merchant */}
              <div className="w-full ">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Merchants
                </label>
                <MultiSelect
                  options={merchants.map((t) => ({
                    label: t.name,
                    value: t.id,
                  }))}
                  selectedValues={passData.merchants}
                  setSelectedValues={(values) =>
                    onChangeFormData({
                      name: "merchants",
                      value: values,
                    })
                  }
                />
              </div>

              <FormSectionHeader text="Benifits" />
              {passData?.benefits?.map((data, index) => (
                <>
                  <div className="flex flex-wrap border-b border-gray-300 py-1 text-center text-base font-medium text-gray-400 dark:border-gray-600 dark:text-white ">
                    <h3>Benifit&nbsp;#&nbsp;{index + 1}</h3>
                  </div>

                  <div
                    className="flex w-full flex-col gap-2 md:flex-row"
                    key={index}
                  >
                    {/* benifit Title  */}
                    <div className="w-full md:w-4/12">
                      <label className="mb-1 block text-base font-medium text-gray-800">
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        placeholder="Title"
                        value={data?.title}
                        onChange={(e) =>
                          handleBenifitChange(index, "title", e.target.value)
                        }
                        className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                      />
                    </div>
                    {/* benifit subTitle  */}
                    <div className="w-full md:w-6/12">
                      <label className="mb-1 block text-base font-medium text-gray-800">
                        SubTitle
                      </label>
                      <input
                        type="text"
                        name="sub_title"
                        placeholder="Sub Title"
                        value={data?.sub_title}
                        onChange={(e) =>
                          handleBenifitChange(
                            index,
                            "sub_title",
                            e.target.value
                          )
                        }
                        className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                      />
                    </div>
                    <button
                      type="button"
                      className="flex w-full items-center justify-center rounded-md bg-red-500 py-[9px] font-semibold text-white hover:bg-red-800 focus:outline-none md:h-fit md:w-2/12 md:place-self-end"
                      onClick={() => removeBenefit(index)}
                    >
                      <FaTrash className="size-5" />
                    </button>
                  </div>
                </>
              ))}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="flex items-center justify-center rounded-md bg-brand-500 px-6 py-2 font-semibold text-white hover:bg-brand-800 focus:outline-none"
                  onClick={addBenefit}
                >
                  <FaPlus className="size-4" />
                  &nbsp;Add more
                </button>
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-between">
            <button
              disabled={loading}
              type="submit"
              // onClick={() => handleSubmit()}
              className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PassForm;
