import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import { useNavigate } from "react-router-dom";
import { formatLocalTimeDate } from "utils/App.utils";

function MembershipClaimTableCard({ claims, onClickNFT }) {
  const navigate = useNavigate();

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="h-10 w-9" />
      {"Customer"}
    </div>,
    <div className="flex items-center gap-2">
      <div className="h-10 w-9" />
      {"Merchant"}
    </div>,
    "NFT Card",
    "Minting Date",
    "Minting Fee",
  ];

  const records = claims.map((claim) => ({
    1: (
      <div className="flex items-center gap-2">
        <div className="h-10 w-9">
          <img
            className="h-9 w-9 cursor-pointer rounded-full"
            src={claim?.customer_id?.profile_pic ?? ProfileIcon}
            alt=""
            onClick={() => {
              if (claim?.customer_id?.id) {
                navigate(`/customer/profile/${claim?.customer_id?.id}`);
              }
            }}
          />
        </div>
        {claim?.customer_id?.name?.trim()?.split(" ")?.join("\u00A0") ?? "N/A"}
      </div>
    ),
    2: (
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => navigate(`/merchants/update/${claim.merchant_id.id}`)}
      >
        <div className=" h-10  w-9">
          <img
            className="h-9 w-9 rounded-full"
            src={claim?.merchant_id?.logo || ProfileIcon}
            alt=""
          />
        </div>
        {claim?.merchant_id?.name}
      </div>
    ),
    nftCard: (
      <div className=" h-10 w-14 ">
        <img
          className="h-9 w-full  rounded-md"
          src={claim.token_uri}
          onClick={() => onClickNFT(claim.token_uri)}
          alt=""
        />
      </div>
      // <div className="w-1 flex h-10 items-center justify-center">
      //   <img
      //     onClick={() => onClickNFT(claim.token_uri)}
      //     className="h-full w-full rounded-md border-none object-cover"
      //     src={claim.token_uri}
      //     alt=""
      //   />
      // </div>
    ),
    mintDate: formatLocalTimeDate(claim?.created_at),
    mintFee: claim?.fee,
  }));

  return (
    <div>
      <Card extra={" w-full bg-white"}>
        <CardHeader label={"Membership Claims"} />
        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MembershipClaimTableCard;
