import { setAlert } from "../alert/alert.actions";
import * as ActionTypes from "../actionTypes";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import WhatsappNotification from "../../services/whatsappNotification/WhatsappNotification";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NOTIFICATIONS_LOADING,
    payload: loading,
  });
};

export const setNotifications = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NOTIFICATIONS,
    payload: data,
  });
};

export const setQueues = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_QUEUES,
    payload: data,
  });
};

export const resetNotificationState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_NOTIFICATIONS_STATE,
  });
};

export const getNotifications = (page, limit) => async (dispatch) => {
  try {
    const response = await WhatsappNotification.getNotifications(page, limit);
    if (response.data && response.status == 1) {
      dispatch(setNotifications(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to fetch Whatsapp Notifications!",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
export const getQueues = (page, limit, template_id) => async (dispatch) => {
  try {
    const response = await WhatsappNotification.getQueues(
      page,
      limit,
      template_id
    );
    if (response.data && response.status == 1) {
      dispatch(setQueues(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to fetch Notification Queues!",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendNotification = (formData, navigate) => async (dispatch) => {
  try {
    const response = await WhatsappNotification.sendNotification(formData);
    if (response.data && response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Whatsapp Notifications Sent Successfully!",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/whatsAppNotifications");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to send Whatsapp Notifications!",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
