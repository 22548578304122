import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getSlotMachineData = () => {
  return RestClient.Get(`${API_URL.GET_SLOT_MACHINE_DATA}`);
};

const saveSlotMachineData = (payload) => {
  return RestClient.Post(API_URL.SAVE_SLOT_MACHINE_DATA, payload);
};

const exportedObject = {
  getSlotMachineData,
  saveSlotMachineData,
};

export default exportedObject;
