import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteMerchantType } from "actions/merchants/merchants.actions";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import SortTable from "../components/sortTable";

function MerchantTypesTableCard({ types }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [typeData, setTypeData] = useState({});

  const onClickDelete = (type) => {
    setTypeData(type);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantType(typeData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setTypeData(null);
  };
  const tableHeaders = [
    { value: "Name", sort: true },
    { value: "Created By", sort: true },
    { value: "Actions", sort: false },
  ];

  const records = types.map((type) => ({
    name: { value: type?.name ?? "", sortKey: type?.name },
    created_by: {
      value: type?.created_by?.name ?? "",
      sortKey: type?.created_by?.name,
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) ? (
            <MdOutlineEdit
              onClick={() => navigate(`/merchant-types/update/${type.id}`)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
            />
          ) : null}
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.FULL_ACCESS
          ) ? (
            <MdOutlineDelete
              onClick={() => onClickDelete(type)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
            />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{typeData.name}" merchant type</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" w-full bg-white"}>
        <CardHeader
          label={"Merchants Types"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/merchant-types/create"),
                    variant: "primary",
                  },
                ]
              : null
          }
        />

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MerchantTypesTableCard;
