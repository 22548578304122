import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  invoicesCount: 0,
  invoices: [],
  loading: false,
};

export const invoiceReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_INVOICES:
      return {
        ...state,
        invoicesCount: payload?.meta?.total_documents || 0,
        invoices: payload.receipts,
        loading: false,
      };
    case ActionTypes.UPDATE_INVOICE_STATUS_FLAG:
      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === payload.receipt.id
            ? { ...invoice, status: payload.receipt.status }
            : invoice
        ),
        loading: false,
      };
    case ActionTypes.SET_INVOICE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_INVOICE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
