import PhoneNumberInputString from "../components/phoneNumberInputString";

const ContactInfo = ({ merchantData, onChangeFormData }) => {
  const onChangeContactInfo = ({ name, value }) => {
    let contact_info = merchantData.contact_info;

    contact_info[name] = value;
    onChangeFormData({ name: "contact_info", value: contact_info });

    if (name === "website") onChangeFormData({ name: "website_url", value });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col justify-between gap-3 md:flex-row">
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Phone
          </label>
          <PhoneNumberInputString
            phone_number={merchantData.contact_info.phone}
            onPhoneNumberChange={(phone_number) =>
              onChangeContactInfo({
                name: "phone",
                value: phone_number,
              })
            }
          />
        </div>
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Public Display Contact
          </label>
          <PhoneNumberInputString
            phone_number={merchantData.contact_info.public_phone}
            onPhoneNumberChange={(phone_number) =>
              onChangeContactInfo({
                name: "public_phone",
                value: phone_number,
              })
            }
          />
        </div>
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Internal Contact
          </label>
          <PhoneNumberInputString
            phone_number={merchantData.contact_info.internal_phone}
            onPhoneNumberChange={(phone_number) =>
              onChangeContactInfo({
                name: "internal_phone",
                value: phone_number,
              })
            }
          />
        </div>
      </div>

      {/* Email and  website */}
      <div className="flex flex-col justify-between gap-2 md:flex-row">
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Email
          </label>
          <input
            required
            type="email"
            name="email"
            value={merchantData.contact_info.email}
            onChange={(e) =>
              onChangeContactInfo({ name: "email", value: e.target.value })
            }
            className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
          />
        </div>
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Website Url
          </label>
          <input
            required
            type="url"
            name="website"
            value={merchantData.contact_info.website}
            onChange={(e) =>
              onChangeContactInfo({ name: "website", value: e.target.value })
            }
            className="w-full rounded-md border border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
