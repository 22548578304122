import { parsePhoneNumberFromString } from "libphonenumber-js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import _ from "lodash";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

export const submitFormHandler = (formRef) => {
  if (formRef) {
    if (typeof formRef.requestSubmit === "function") {
      formRef.requestSubmit();
    } else {
      // Polyfill for requestSubmit
      const event = new Event("submit", {
        bubbles: true,
        cancelable: true,
      });
      formRef.dispatchEvent(event);
    }
  }
};

export const formatNumber = (value, decimals = 1) => {
  //return "$"+value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  if (value > 999 && value < 1000000) {
    return (value / 1000).toFixed(decimals) + " K"; // convert to K for valueber from > 1000 < 1 million
  } else if (value >= 1000000 && value < 1000000000) {
    return (value / 1000000).toFixed(decimals) + " M"; // convert to M for valueber from > 1 million
  } else if (value >= 1000000000 && value < 1000000000000) {
    return (value / 1000000000).toFixed(decimals) + " B"; // convert to M for valueber from > 1 million
  } else if (value >= 1000000000000) {
    return (value / 1000000000000).toFixed(decimals) + " T"; // convert to M for valueber from > 1 million
  } else if (value < 1000) {
    return value.toFixed(decimals); // if value < 1000, nothing to do
  }
};

export const isValidCode = (code) => {
  return code === "" || /^\+\d{0,4}$/.test(code);
};

export const isValidNumber = (number) => {
  return /^\d*$/.test(number);
};

export const parseStringNumber = (number) => {
  if (!number || typeof number !== "string") return;
  const parsedNumber = parsePhoneNumber(number);
  const newCode = `+${parsedNumber?.countryCallingCode ?? "966"}`;
  const phoneNumber = `${parsedNumber?.nationalNumber ?? "123456789"}`;
  return { code: newCode, number: phoneNumber };
};

export const validatePhoneNumber = (number) => {
  const phoneNumber = parsePhoneNumberFromString(number);
  // console.log({ phoneNumberProerties: phoneNumber });
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.isValid();
  } else {
    return false;
  }
};
export const validateCodePhoneNumber = (code, number) => {
  const fullNumber = `${code}${number}`;
  const phoneNumber = parsePhoneNumberFromString(fullNumber);
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.isValid();
  } else {
    return false;
  }
};

export const roundToTwoDecimals = (value) => {
  const number = parseFloat(value);
  return isNaN(number)
    ? value
    : number % 1 !== 0
    ? number.toFixed(2)
    : value.toString();
};

export const formatNumberWithCommas = (amount) =>
  typeof amount === "number"
    ? amount.toLocaleString()
    : typeof amount === "string"
    ? parseFloat(amount).toLocaleString()
    : amount;

export const formatLocalTimeDate = (
  date,
  format = "MMM DD, YYYY hh:mm:ss A"
) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(relativeTime);
  const user_timeZone = dayjs.tz.guess();

  return dayjs(date).tz(user_timeZone).format(format);
};

export const convertDateTimeToUTC = (localDateTime) => {
  dayjs.extend(utc);
  return dayjs(localDateTime).utc().format();
};

export const truncateText = (text, maxLength, ellipsis = "...") => {
  if (!text || typeof text !== "string") {
    return "N/A"; // Handle empty or non-string inputs
  }

  if (text.length <= maxLength) {
    return text;
  }

  return text.slice(0, maxLength) + ellipsis;
};

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const getFingerpeintDeviceId = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId;
};

export const filterRecentSocketConnections = (data) => {
  let filteredData = {};
  Object.entries(data).forEach(([key, value]) => {
    if (!Array.isArray(value)) return (filteredData[key] = value);

    filteredData[key] = value.reduce((acc, current) => {
      // Check if user is already in the accumulator
      if (
        // admin_console_users
        (current.user_id &&
          !acc.some((item) => item.user_id === current.user_id)) ||
        // merchant_console_users -> Merchants
        (current.merchant_id &&
          !acc.some((item) => item.merchant_id === current.merchant_id)) ||
        // merchant_console_users -> Brands
        (current.brand_id &&
          !acc.some((item) => item.brand_id === current.brand_id)) ||
        // mobile_app_users
        (current.customer_id &&
          !acc.some((item) => item.customer_id === current.customer_id)) ||
        // vendor_app_users
        (current.device_id &&
          !acc.some((item) => item.device_id === current.device_id)) ||
        // cashier_app_users
        (current.merchant_id &&
          !acc.some((item) => item.merchant_id === current.merchant_id))
      ) {
        acc.push(current);
      }
      return acc;
    }, []);
  });

  return filteredData;
};

export const sortData = (data, sortConfig) => {
  const { column, direction } = sortConfig;

  // console.log(data);

  if (column === null || column === undefined) {
    return data; // No sorting needed or empty data
  }
  const sortKey = Object.keys(data[0])[column];

  return data.slice().sort((a, b) => {
    const aValue = a[sortKey].sortKey;
    const bValue = b[sortKey].sortKey;

    console.log("comparing", sortKey, "values", aValue, bValue);

    if (typeof aValue === "string" && typeof bValue === "string") {
      // Prioritize numeric comparisons
      const numA = Number(aValue.replace(",", "."));
      const numB = Number(bValue.replace(",", "."));

      if (!isNaN(numA) && !isNaN(numB)) {
        return direction === "asc" ? numA - numB : numB - numA;
      } else {
        // Handle date strings
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);

        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return direction === "asc"
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        } else {
          // Default to string comparison
          return direction === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }
      }
    } else if (typeof aValue === "number") {
      return direction === "asc" ? aValue - bValue : bValue - aValue;
    } else if (typeof aValue === "boolean") {
      return direction === "asc"
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    } else if (typeof aValue === "object" && aValue instanceof Date) {
      // Handle dates
      return direction === "asc"
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    } else {
      console.warn("Unsupported data type for sorting:", aValue, bValue);
      return 0;
    }
  });
};
