import timeZones from "./timezones.json";

export const FORM_TYPE = {
  CREATE: "Create",
  UPDATE: "Update",
};

export const FILE_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
};

export const PUSH_NOTIFICATION_PLATFORM = {
  ANDROID: "android",
  IOS: "ios",
  BOTH: "both",
};

export const TRANSACTION_TYPE = {
  EARNING: "earning",
  REDEMPTION: "redemption",
};

export const TRANSACTION_STAUS = {
  DISABLED: "disabled",
  ENABLED: "enabled",
  PENDING: "pending",
};

export const TIME_ZONES = timeZones;

export const IMAGE_SIZES = {
  LOYALTY_CARD: {
    width: 1372,
    height: 732,
  },
  MERCHANT_LISTING: {
    width: 920,
    height: 492,
  },
  MERCHANT_COVER: {
    width: 920,
    height: 492,
  },
  MERCHANT_LOGO: {
    width: 512,
    height: 512,
  },
  MERCHANT_TAG: {
    width: 512,
    height: 512,
  },
  MERCHANT_PROMORION_TAG: {
    width: 1125,
    height: 2436,
  },
  LANGUAGE_FLAG: {
    width: 120,
    height: 120,
  },
  SLIDER: {
    width: 920,
    height: 492,
  },
  CATEGORY: {
    width: 512,
    height: 512,
  },
  CLASSIFICATION: {
    width: 120,
    height: 120,
  },
};
export const RATING_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  DELETED: "deleted",
  REJECTED: "rejected",
};
export const RECEIPT_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
};

export const CART_RULE_TYPE = {
  MERCHANT: "merchant",
  NETWORK: "network",
};

export const CART_RULE_ACTION_TYPE = {
  PERCENTAGE: "percentage",
  FIXED: "fixed",
};
export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];