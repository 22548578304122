import { useEffect, useRef, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlineArrowDropDown } from "react-icons/md";

const MultiSelect = ({
  title,
  options,
  selectedValues,
  setSelectedValues,
  search = true,
}) => {
  // console.log({ options });
  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  // Filtering Options
  const filteredOptions = searchQuery
    ? options.filter((option) =>
        option?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    : options;

  // console.log({ filteredOptions });

  // Scroll-to-Match
  const optionsRef = useRef(null);

  useEffect(() => {
    if (searchQuery) {
      const firstMatch = optionsRef.current?.querySelector(
        `.block.truncate:first-child` // Adjust selector as needed
      );
      if (firstMatch) {
        firstMatch.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [searchQuery]);

  const getLabel = (value) => {
    const option = options.find((opt) => opt.value == value);
    return option?.label || "unknown";
  };

  const handleSelect = (value) => {
    let temp_values = [];
    if (selectedValues.includes(value))
      temp_values = selectedValues.filter((val) => val !== value);
    else temp_values = [...selectedValues, value];
    setSelectedValues(temp_values);
  };

  return (
    <div className="h-full w-full">
      <div class="flex w-full flex-col items-center">
        <div class="w-full">
          <div
            onClick={() => setOpen((prev) => !prev)}
            class="flex items-center gap-2 divide-x-2 divide-gray-600 rounded border border-gray-200 bg-white p-1"
          >
            <div class="flex flex-auto items-center gap-2 overflow-x-auto">
              {selectedValues.map((value) => (
                <div
                  key={Math.random()}
                  className="flex w-auto items-center justify-between gap-2 rounded-full border border-gray-200 px-2 py-1 text-sm"
                >
                  <p className="flex flex-auto text-nowrap">
                    {getLabel(value)}
                  </p>
                  <ImCancelCircle
                    onClick={() => handleSelect(value)}
                    className=" h-4 w-4 text-gray-500 hover:text-gray-900"
                  />
                </div>
              ))}
              {!selectedValues.length ? (
                <p className="p-1 text-gray-400">
                  {title ?? "click on arrow to open dropdown"}
                </p>
              ) : null}
            </div>
            <MdOutlineArrowDropDown
              // onClick={() => setOpen((prev) => !prev)}
              className="h-8 w-8  text-gray-800"
            />
          </div>
        </div>
        <div class="relative z-50 flex w-full flex-col items-center">
          {open && options.length ? (
            <div class="top-100 lef-0 max-h-select svelte-5uyqqj absolute z-40 w-full overflow-y-auto rounded bg-white shadow">
              <div class="flex w-full flex-col">
                {/* search field */}
                {search && (
                  <div className="sticky top-0 z-10  bg-white p-1">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className=" w-full rounded-md  border-[1px] border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                    />
                  </div>
                )}
                {/* options map */}
                {filteredOptions.map((option) => (
                  <span
                    onClick={() => handleSelect(option.value)}
                    key={Math.random()}
                    class="w-full cursor-pointer border-b border-gray-100 hover:bg-indigo-100"
                  >
                    <div
                      class={`border-transparent relative flex w-full items-center border-l-2 p-2 pl-2 ${
                        selectedValues.includes(option.value)
                          ? "border-indigo-500"
                          : ""
                      }`}
                    >
                      <div class="flex w-full items-center">
                        <div class="mx-2 leading-6">{option.label} </div>
                      </div>
                    </div>
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
