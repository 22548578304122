import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import AffiliateOrderService from "../../services/affiliateOrders/AffiliateOrderService";
import * as ActionTypes from "../actionTypes";
import { downloadFile } from "utils/App.utils";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AFFILIATE_ORDER_LOADING,
    payload: loading,
  });
};

export const setOrders = (data) => (dispatch) => {
  console.log({ setOrders_data: data });
  dispatch({
    type: ActionTypes.SET_AFFILIATE_ORDERS,
    payload: data,
  });
};

export const resetOrderState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_AFFILIATE_ORDER_STATE,
  });
};

export const getAffiliateOrders =
  (page, limit, customer_id, store_id, order_number, startDate, endDate) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await AffiliateOrderService.getAffiliateOrders(
        page,
        limit,
        customer_id,
        store_id,
        order_number,
        startDate,
        endDate
      );
      if (response.status == 1 && response.data) {
        console.log({ SET_ORDERSSSS: response.data });
        dispatch(setOrders(response.data));
        return;
      }
      dispatch(
        setAlert(response.message ?? "failed to load orders", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const exportAffiliateOrders =
  (
    merchant_id,
    customer_id,
    country_id,
    state_id,
    city_id,
    startDate,
    endDate
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await AffiliateOrderService.exportAffiliateOrders(
        merchant_id,
        customer_id,
        country_id,
        state_id,
        city_id,
        startDate,
        endDate
      );
      if (response.status == 0) {
        dispatch(
          setAlert(
            response.message ??
              "Something went wrong while downloading the CSV",
            AlertTypes.ERROR
          )
        );
        return;
      }
      downloadFile(response, "orders.csv");
    } catch (error) {
      dispatch(
        setAlert(
          "Something went wrong while downloading the CSV",
          AlertTypes.ERROR
        )
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
