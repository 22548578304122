import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { deleteFacility } from "actions/facility/facility.action";
import SortTable from "../components/sortTable";

const FacilityTableCard = ({ facilities, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [deleteFacilityId, setDeleteFacilityId] = useState(null);

  const onClickDelete = (brandId) => {
    setDeleteFacilityId(brandId);
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
    setDeleteFacilityId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteFacility(deleteFacilityId));
    handleCancelDelete();
  };

  const tableHeaders = [
    { value: "Name", sort: true },
    { value: "Created By", sort: true },
    { value: "Actions", sort: false },
  ];
  const records = facilities.map((facility) => ({
    name: { value: facility?.name ?? "", sortKey: facility?.name ?? "" },
    created_by: {
      value: facility?.created_by?.name ?? "",
      sortKey: facility?.created_by?.name ?? "",
    },
    actions: {
      value: (
        <div className="flex items-center justify-center gap-2">
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.EDITOR_ACCESS
          ) ? (
            <MdOutlineEdit
              onClick={() => navigate(`/facility/update/${facility.id}`)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
            />
          ) : null}
          {hasPermission(
            [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
            PERMISSION_ACTION_TYPES.FULL_ACCESS
          ) ? (
            <MdOutlineDelete
              onClick={() => onClickDelete(facility.id)}
              className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
            />
          ) : null}
        </div>
      ),
      sortKey: null,
    },
  }));
  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the facility with name{" "}
              {
                facilities.find((facility) => facility.id === deleteFacilityId)
                  .name
              }
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Facilities"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/facility/create"),
                    variant: "primary",
                  },
                ]
              : null
          }
        />
        {children}

        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default FacilityTableCard;
