export const PASS_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const SUBSCRIPTION_PERIOD = {
  MONTHLY: "monthly",
  ANNUALLY: "annually",
  WEEKLY: "weekly",
};
