import Card from "components/card";
import LineChart from "components/charts/LineChart";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";

import { setAlert } from "actions/alert/alert.actions";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { AlertTypes } from "reducers/alert/alert.reducer";
import DashboardService from "services/dashboard/DashboardService";
import { formatNumber } from "utils/App.utils";

const EarningsRedemptionsLineChart = () => {
  const dispatch = useDispatch()
  const { new_transaction_event } = useSelector((state) => state.events);
  const [loading, setLoading] = useState(false)
  const colors = { earnings: "#40F585", redemptions: "#f5715D" }
  const [chartOptions, setChartOptions] = useState(lineChartOptionsTotalSpent)
  const [chartSeries, setChartSeries] = useState(lineChartDataTotalSpent)
  const [dataRange, setDateRange] = useState("monthly")
  const [totalEarnings, setTotalEarnings] = useState(0)
  const [totalRedemptions, setTotalRedemptions] = useState(0)

  const getEarningRedemptionChartData = async (dataRange) => {
    try {
      setLoading(true);
      const response = await DashboardService.getEarningRedemptionChartData(dataRange);
      if (response.status == 1) {
        const earnings = {
          name: "Earnings",
          data: response.data.earnings.data.map(({ points, date }) => ({ x: date, y: points })),
          color: colors.earnings,
        }
        const redemptions = {
          name: "Redemptions",
          data: response.data.redemptions.data.map(({ points, date }) => ({ x: date, y: points })),
          color: colors.redemptions,
        }
        setChartOptions({
          ...lineChartOptionsTotalSpent,
          // type: "datetim",
        })
        setChartSeries([earnings, redemptions])
        setTotalEarnings(response.data.earnings.total)
        setTotalRedemptions(response.data.redemptions.total)
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load dashboard stats",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return
    getEarningRedemptionChartData(dataRange)
  }, [dataRange, new_transaction_event.id])


  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Total Spent
        </h2>
        <div className="mb-6 flex items-center justify-center">
          <div className="relative">
            <FaRegCalendarAlt className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-600" />
            <select
              disabled={loading}
              value={dataRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="linear flex items-center justify-center gap-2 rounded-lg bg-lightPrimary p-2 pl-8 text-gray-600 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:hover:opacity-90 dark:active:opacity-80"
            >
              <option value="weekly">This Week</option>
              <option
                value="monthly"
                className="text-sm font-medium text-gray-600"
              >
                This Month
              </option>
              <option value="yearly">This Year</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex h-full w-full flex-col">
        <div className="flex w-full flex-col">
          {/* <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white">
            $37.5K
          </p> */}
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Earnings</p>
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500">
                {formatNumber(totalEarnings, 1)}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-sm text-gray-600">Redemptions</p>
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropDown className="font-medium text-red-500" />
              <p className="text-sm font-bold text-red-500">
                {formatNumber(totalRedemptions, 1)}
              </p>
            </div>
          </div>
        </div>
        <div className="max-h-fit w-full ">
          <LineChart options={chartOptions} series={chartSeries} />
        </div>
      </div>
    </Card>
  );
};

export default EarningsRedemptionsLineChart;
