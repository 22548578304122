import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

export const getTopUps = (
  page = 1,
  limit = 50,
  customer_id,
  topup_number,
  startDate,
  endDate
) => {
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const topUpNumber = topup_number ? `&topup_number=${topup_number}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  return RestClient.Get(
    `${API_URL.GET_TOPUPS}?page=${page}&limit=${limit}${customer}${topUpNumber}${start_date}${end_date}`
  );
};

const sendManualTopup = (payload) => {
  return RestClient.Post(API_URL.SEND_MANUAL_TOPUP, payload);
};


const exportedObject = {
  getTopUps,
  sendManualTopup,
};

export default exportedObject;
