import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  reportsCount: 0,
  reports: [],
  loading: false,
};

export const reportsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_REPORTS:
      return {
        ...state,
        reportsCount: payload?.meta?.total_documents || 0,
        reports: payload,
        loading: false,
      };
    case ActionTypes.SET_REPORT_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_REPORT_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
