import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFileCsv } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";

const FormFileUploaderCSV = ({ handleUpload }) => {
  const [error, setError] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");

  const onDrop = (acceptedFiles) => {
    setError("");
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setUploadedFileName(file.name);
      handleUpload(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: false,
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <div className="h-full w-full">
      <div
        {...getRootProps()}
        className="border-1 flex h-24 w-full cursor-pointer items-center justify-between rounded-xl border border-dashed border-green-400  p-2 hover:border-green-500"
      >
        <input {...getInputProps()} />
        <div className="flex justify-center p-4">
          {uploadedFileName ? (
            <div className="flex flex-col items-center gap-1">
              <FaFileCsv className="h-14 w-14 rounded-lg" />
              <span className="text-xs text-gray-700">{uploadedFileName}</span>
            </div>
          ) : (
            <MdFileUpload className="mx-auto block h-16 w-16 max-w-[100px]" />
          )}
        </div>

        <div className="flex flex-auto flex-col items-center justify-center lg:flex-row lg:gap-4">
          <p className="whitespace-wrap text-center text-sm text-gray-900">
            Drag or click to upload your .CSV file
          </p>
          {error ? (
            <p className="py-1 text-center text-xs text-red-600">{error}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormFileUploaderCSV;
