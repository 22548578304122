import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  passesCount: 0,
  passes: [],
  pass: null,
  passMultiLang: {},
  loading: false,
};

export const passesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_PASSES:
      return {
        ...state,
        passesCount: payload?.meta?.total_documents || 0,
        passes: payload.passes,
        loading: false,
      };
    case ActionTypes.SET_PASS:
      return {
        ...state,
        pass: payload.pass,
        passMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_PASS:
      return {
        ...state,
        passesCount: state.passesCount - 1,
        passes: state.passes.filter((pass) => pass.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_PASS_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_PASS_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
