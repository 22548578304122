import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const GetAllInvoices = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_INVOICES}?page=${page}&limit=${limit}`);
};
const UpdateInvoice = (invoiceId, data) => {
  return RestClient.Put(`${API_URL.UPDATE_INVOICES}/${invoiceId}`, data);
};

const exportedObject = {
  GetAllInvoices,
  UpdateInvoice,
};

export default exportedObject;
