import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { deleteStoreCategory } from "actions/storeCategories/storeCategories.actions";

const StoreCategoriesTableCard = ({ categories, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const onClickDelete = (categoryId) => {
    setDeleteCategoryId(categoryId);
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
    setDeleteCategoryId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStoreCategory(deleteCategoryId));
    handleCancelDelete();
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Name"}
    </div>,
    "Display Order",
    "Status",
    "Created By",
    "Actions",
  ];
  const records = categories.map((category) => ({
    image: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img className="h-9 w-9 rounded-none" src={category.image} alt="" />
        </div>
        {category.name.split(" ").join("\u00A0")}
      </div>
    ),
    order: category.display_order,
    status: category.category_status,
    created_by: category?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/storeCategories/update/${category.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(category.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));
  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the category with name{" "}
              {
                categories.find((category) => category.id === deleteCategoryId)
                  .name
              }
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Store Categories"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.AFFILIATE_MARKETING],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/storeCategories/create"),
                    variant: "primary",
                  },
                ]
              : null
          }
        />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default StoreCategoriesTableCard;
