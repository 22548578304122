import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { retrieveSystemSettings } from "selectors/settings/settings.selector";
import StoreTableCard from "./store-table-card";
import { getStores } from "actions/store/store.actions";

const Store = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(retrieveSystemSettings);
  const limit = default_page_size;

  const dispatch = useDispatch();
  const { storesCount, stores } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getStores(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <StoreTableCard stores={stores} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={storesCount}
      />
    </div>
  );
};

export default Store;
