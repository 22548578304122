import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import InvoiceService from "../../services/invoices/InvoiceService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_INVOICE_LOADING,
    payload: loading,
  });
};

export const setInvoices = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_INVOICES,
    payload: data,
  });
};

export const updateInvoiceStatusFlag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_INVOICE_STATUS_FLAG,
    payload: data,
  });
};

export const resetInvoiceState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_INVOICE_STATE,
  });
};

export const GetAllInvoices = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await InvoiceService.GetAllInvoices(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setInvoices(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load invoices", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const UpdateInvoice = (invoiceId, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await InvoiceService.UpdateInvoice(invoiceId, data);
    if (response.status == 1 && response.data) {
      dispatch(updateInvoiceStatusFlag(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to update invoice", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
