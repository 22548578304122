import merchantPointsIcon from "assets/img/airplay.png";
import mithuPointsIcon from "assets/img/mithu.png";
import Card from "components/card";
import { Link, useNavigate } from "react-router-dom";
import { roundToTwoDecimals } from "utils/App.utils";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { FaArrowDown, FaArrowUp, FaBan } from "react-icons/fa";
import { TRANSACTION_TYPE, TRANSACTION_STAUS } from "constants/app.constants";
import { formatNumberWithCommas } from "utils/App.utils";
import { formatLocalTimeDate } from "utils/App.utils";
import { IoIosTimer } from "react-icons/io";
import { GiSandsOfTime } from "react-icons/gi";

const getSourceClasses = (status) => {
  switch (status) {
    case "topup":
      return "bg-yellow-200 text-yellow-800";
    case "affiliate_marketing_order":
      return "bg-blue-200 text-blue-800";
    case "order":
      return "bg-green-200 text-green-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

function TransactionsTableCard({ transactions, children }) {
  const navigate = useNavigate();

  const handleEntityClick = (entity) => {
    if (entity.entity_type === "merchant") {
      navigate(`/merchants/update/${entity.entity.id}`);
    } else return;
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Customer"}
    </div>,
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      <div className="flex items-center gap-1">
        <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
        <p>Merchant/Store</p>
      </div>
    </div>,
    "Source",
    "Number",
    "Amount",
    "Discount",
    <div className="flex items-center gap-1">
      <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
      <p>Points</p>
    </div>,
    <div className="flex items-center gap-1">
      <img className="h-4 w-4 " src={mithuPointsIcon} alt="" />
      <p>Points</p>
    </div>,
    "Type",
    "Date",
  ];

  const records = transactions.map((transaction) => ({
    1: (
      <Link
        to={`/customer/profile/${transaction?.customer?.id}`}
        className="flex items-center gap-2"
      >
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 cursor-pointer rounded-full"
            src={transaction?.customer?.profile_pic || ProfileIcon}
            alt=""
          />
        </div>{" "}
        {transaction.customer?.name?.trim()?.split(" ").join("\u00A0") ?? "N/A"}
      </Link>
    ),

    2: (
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => handleEntityClick(transaction)}
      >
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 rounded-full"
            src={transaction?.entity?.logo ?? ProfileIcon}
            alt=""
          />
        </div>
        {transaction?.entity?.name?.trim()?.split(" ").join("\u00A0") ?? " "}
      </div>
    ),
    // source: transaction?.reference_type ?? "",
    source: (
      <span
        className={`rounded-full  px-2 py-1 text-xs font-semibold ${getSourceClasses(
          transaction?.reference_type
        )}`}
      >
        {transaction?.reference_type
          ?.replace(/_/g, "\u00A0")
          ?.toUpperCase()
          ?.replace(/MARKETING/g, "")}
      </span>
    ),
    order_number: transaction?.reference?.unique_number ?? "",
    order_amount: `${transaction.entity.currency} ${formatNumberWithCommas(
      Number(transaction?.reference?.amount || 0).toFixed(2)
    )}`,
    discount: `${transaction.entity.currency} ${formatNumberWithCommas(
      Number(transaction?.reference?.discount_amount || 0).toFixed(2)
    )}`,
    entity_points: (
      <div className="flex items-center justify-between gap-1 text-nowrap">
        <p className="flex">
          {transaction?.entity_transaction_status ==
          TRANSACTION_STAUS.ENABLED ? (
            transaction?.transaction_type == TRANSACTION_TYPE.EARNING ? (
              <FaArrowDown className={`h-6 w-6 p-1 text-green-500`} />
            ) : (
              <FaArrowUp className={`h-6 w-6 p-1 text-red-500`} />
            )
          ) : (
            <FaBan className={`h-6 w-6 p-1 text-gray-500`} />
          )}
          {transaction?.entity_transaction_status == TRANSACTION_STAUS.ENABLED
            ? `${Number(transaction?.entity_points || 0).toFixed(2)} pts`
            : `${Number(0).toFixed(2)} pts`}
          {transaction?.entity_transaction_status ==
          TRANSACTION_STAUS.PENDING ? (
            <GiSandsOfTime className={`h-6 w-6 p-1 text-gray-500`} />
          ) : null}
        </p>
      </div>
    ),
    network_points: (
      <div className="flex items-center justify-between gap-1 text-nowrap">
        <p className="flex">
          {transaction?.network_transaction_status ==
          TRANSACTION_STAUS.ENABLED ? (
            transaction?.transaction_type == TRANSACTION_TYPE.EARNING ? (
              <FaArrowDown className={`h-6 w-6 p-1 text-green-500`} />
            ) : (
              <FaArrowUp className={`h-6 w-6 p-1 text-red-500`} />
            )
          ) : (
            <FaBan className={`h-6 w-6 p-1 text-gray-500`} />
          )}
          {transaction?.network_transaction_status == TRANSACTION_STAUS.ENABLED
            ? `${Number(transaction?.network_points || 0).toFixed(2)} pts`
            : `${Number(0).toFixed(2)} pts`}

          {transaction?.network_transaction_status ==
          TRANSACTION_STAUS.PENDING ? (
            <GiSandsOfTime className={`h-6 w-6 p-1 text-gray-500`} />
          ) : null}
        </p>
      </div>
    ),
    type: (
      <span
        className={`inline-block w-full text-nowrap rounded-full px-2 py-1 text-center text-xs font-bold ${
          transaction?.transaction_type == TRANSACTION_TYPE.EARNING
            ? "bg-green-600"
            : "bg-red-600"
        }  text-white`}
      >
        {transaction?.transaction_type == TRANSACTION_TYPE.EARNING
          ? "Earn"
          : "Redeem"}
      </span>
    ),
    created_at: formatLocalTimeDate(transaction.created_at),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Transactions"} />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default TransactionsTableCard;
