import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getRules = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_CART_RULES}?page=${page}&limit=${limit}`
  );
};

const getRule = (rule_id) => {
  return RestClient.Get(`${API_URL.GET_CART_RULE}/${rule_id}`);
};

const createRule = (payload) => {
  return RestClient.Post(API_URL.CREATE_CART_RULE, payload);
};

const updateRule = (rule_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_CART_RULE}/${rule_id}`, payload);
};

const toggleRulePublishStatus = (rule_id) => {
  return RestClient.Put(
    `${API_URL.TOGGLE_CART_RULE_PUBLISH_STATUS}/${rule_id}`
  );
};
const updateLanguageRule = (rule_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_CART_RULE_MULTI_LANG}/${rule_id}`,
    payload
  );
};

const deleteRule = (rule_id) => {
  return RestClient.Delete(`${API_URL.DELETE_CART_RULE}/${rule_id}`);
};

const exportedObject = {
  getRules,
  getRule,
  createRule,
  updateRule,
  toggleRulePublishStatus,
  updateLanguageRule,
  deleteRule,
};

export default exportedObject;
