import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function Pagination({ page, setPage, limit, totalCount }) {
  // console.log({ page, limit, totalCount })

  const calculatePaginateRecords = () => {
    const record_from = (page - 1) * limit + 1;
    const record_to = page * limit;
    const total_record = Math.ceil(totalCount);
    return {
      total_record,
      record_from: record_from <= total_record ? record_from : total_record,
      record_to: record_to <= total_record ? record_to : total_record,
      pages: Math.ceil(totalCount / limit),
    };
  };

  const countPaginate = calculatePaginateRecords();
  return (
    <div className="mt-2 flex flex-row items-center justify-between rounded-md border border-indigo-300 bg-indigo-50 p-2">
      <div className="w-full">
        <span>
          Showing {countPaginate.record_from} to {countPaginate.record_to} of{" "}
          {countPaginate.total_record} results
        </span>
      </div>
      <div className="flex w-full justify-end gap-4">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page <= 1}
          class={`
          flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-gray-100 px-3 text-sm font-medium text-gray-500
          ${
            page > 1
              ? "cursor-pointer bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-800"
              : ""
          }
          `}
        >
          <FaArrowLeft className="h-3.5 w-3.5" />
          <span>Previous</span>
        </button>
        <button
          disabled={page >= countPaginate.pages}
          onClick={() => setPage(page + 1)}
          class={`
          flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-gray-100 px-3 text-sm font-medium text-gray-500
          ${
            !(page >= countPaginate.pages)
              ? "cursor-pointer bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-800"
              : ""
          }
          `}
        >
          <span>Next</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </button>
      </div>
    </div>
  );
}