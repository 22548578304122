import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

const PhoneNumberInputString = ({ phone_number, onPhoneNumberChange }) => {
  const [phoneNumber, setPhoneNumber] = useState(phone_number || "");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setPhoneNumber(phone_number);
  }, [phone_number]);

  const handleNumberChange = (number) => {
    setPhoneNumber(number);

    // Validate phone number
    if (number) {
      const parsedNumber = parsePhoneNumber(number);
      const isValid = parsedNumber ? isValidPhoneNumber(number) : false;
      setIsValid(isValid);

      if (isValid) {
        onPhoneNumberChange(number);
      } else {
        // Handle invalid phone number, e.g., display an error message
        console.error("Invalid phone number");
      }
    } else {
      onPhoneNumberChange("");
    }
  };

  return (
    <div className="flex w-full items-center justify-center gap-1">
      <div className="flex flex-auto">
        <PhoneInput
          placeholder="Enter phone number"
          international
          countryCallingCodeEditable={false}
          defaultCountry="SA" // Set default country code
          value={phoneNumber}
          onChange={handleNumberChange}
          className={`block w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm outline-none  ${
            !isValid ? "border-red-500" : ""
          }`}
          style={{ outline: "none" }}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInputString;
