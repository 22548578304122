import Card from "components/card";
import { formatLocalTimeDate } from "utils/App.utils";
import CardHeader from "views/admin/components/cardHeader";
import SortTable from "views/admin/components/sortTable";
import Table from "views/admin/components/table";

function OperationsTableCard({ reports, children }) {
  const tableHeaders = [
    { value: "Date", sort: true },
    { value: "Live Outlets", sort: true },
    { value: "Average Customers", sort: true },
    { value: "SignUp CX", sort: true },
    { value: "App Downloads %", sort: true },
    { value: "App Download", sort: true },
    { value: "Second Transaction %", sort: true },
    { value: "Second Transaction CX", sort: true },
  ];
  const records = reports.map((report) => ({
    date: { value: report?.date, sortKey: report?.date },
    liveOutlets: { value: report?.liveOutlets, sortKey: report?.liveOutlets },
    customerAvg: { value: report?.customerAvg, sortKey: report?.customerAvg },
    customersCount: {
      value: report?.customersCount,
      sortKey: report?.customersCount,
    },
    appDownloadPercentage: {
      value: report?.appDownloadPercentage,
      sortKey: report?.appDownloadPercentage,
    },
    appDownloadCount: {
      value: report?.appDownloadCount,
      sortKey: report?.appDownloadCount,
    },
    secondTransactionSumAvg: {
      value: report?.secondTransactionSumAvg,
      sortKey: report?.secondTransactionSumAvg,
    },
    secondTransactionSum: {
      value: report?.secondTransactionSum,
      sortKey: report?.secondTransactionSum,
    },
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Operations Performance Report "} />
        {children}
        <SortTable
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default OperationsTableCard;
